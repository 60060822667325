import React from 'react'

const ClippedImageWithBorder = ({ imageUrl }) => {
  return (
    <svg
      width="260" // SVG container width
      height="260" // SVG container height
      viewBox="-6 -6 145 145" // Defines the coordinate system of the SVG
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id="clip-shape">
          <polygon points="0 64, 64 0, 128 64, 64 128" />
        </clipPath>
      </defs>
      {/* Image with clip-path */}
      <image
        className="object-fill"
        clipPath="url(#clip-shape)"
        href={imageUrl}
        x="-30" // Start position of the image on the X axis
        y="0" // Start position of the image on the Y axis
        width="200" // This should match the viewBox width to cover the entire SVG
        height="200" // This should match the viewBox height
        preserveAspectRatio="xMidYMid meet" // Try 'meet' instead of 'slice'
      />
      {/* Polygon outline with a blue border */}
      <polygon
        points="0 64, 64 0, 128 64, 64 128"
        fill="transparent"
        stroke="#264E97"
        strokeWidth="3"
      />
    </svg>
  )
}

export default ClippedImageWithBorder
