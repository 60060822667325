import React, { useEffect, useRef } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import logoutUser from '../../apis/logout'
import personal from '../../assets/icons/personal.svg'
import security from '../../assets/icons/security.svg'
import balance from '../../assets/icons/currency.svg'
import history from '../../assets/icons/purchase.svg'
import activity from '../../assets/icons/activity.svg'
import complaint from '../../assets/icons/complaint.svg'
import logouticon from '../../assets/icons/logout.svg'
import { useCart } from '../../hooks/CartContext'
import { useAuth } from '../../hooks/AuthContext'

const Sidepar = ({ setActive, active }) => {
  const { cartItems, clearCart } = useCart()
  const navigate = useNavigate()
  const { logout, user } = useAuth()
  const sideparRef = useRef(null)

  const handelLogOut = async (event) => {
    event.preventDefault()
    const packages_id = cartItems.map((item) => item.id)
    const packages_quantity = cartItems.map((item) => item.quantity)
    const cart = {
      packages_id,
      packages_quantity,
    }
    setActive(false)
    document.body.style.overflow = 'auto' // Reset overflow
    try {
      navigate('/', { replace: true })
      await logoutUser(user, cart)
      clearCart()
      logout()
    } catch (error) {}
  }

  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [active])

  useEffect(() => {
    const close = () => {
      setActive(false)
    }

    function handleClickOutside(event) {
      if (sideparRef.current && !sideparRef.current.contains(event.target)) {
        close()
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [setActive])

  return (
    <div ref={sideparRef}>
      <div className=" w-full h-[100vh] py-4 md:py-0  md:h-[88.5vh] md:rounded-br-[20px] bg-gradient-to-t from-blue_header_1 to-blue_header_3 md:from-Sidepar md:to-Sidepar flex flex-col justify-between text-white">
        <div className="flex flex-col gap-[2px] md:gap-1 ">
          <div className=" hidden md:block text-white text-xl  font-bold text-left px-7 py-6">
            Account Setting
          </div>
          <Link
            onClick={() => setActive(false)}
            to="/profile"
            className="flex items-center px-7 py-6 md:py-4 gap-2 bg-blue_primary  md:bg-Sidepar_taps hover:bg-blue_primary active:bg-blue_primary transition-colors"
          >
            <div className=" bg-white bg-opacity-20  p-2 w-10 h-10 rounded-full flex items-center justify-center">
              <img src={personal} alt="" />
            </div>
            Personal Information
          </Link>
          <Link
            onClick={() => setActive(false)}
            to="/profile/security"
            className="flex items-center px-7 py-6 md:py-4 gap-2  bg-blue_primary  md:bg-Sidepar_taps hover:bg-blue_primary active:bg-blue_primary transition-colors"
          >
            <div className=" bg-white bg-opacity-20  p-2 w-10 h-10 rounded-full flex items-center justify-center">
              <img src={security} alt="" />
            </div>
            Security
          </Link>

          <Link
            onClick={() => setActive(false)}
            to="/profile/complaints"
            className="flex items-center px-7 py-6 md:py-4 gap-2  bg-blue_primary  md:bg-Sidepar_taps hover:bg-blue_primary active:bg-blue_primary transition-colors"
          >
            <div className=" bg-white bg-opacity-20  p-2 w-10 h-10 rounded-full flex items-center justify-center">
              <img src={complaint} alt="" />
            </div>
            Complaints
          </Link>

          <Link
            onClick={() => setActive(false)}
            to="/profile/account-balance"
            className="flex items-center px-7 gap-2  py-6 md:py-4 bg-blue_primary  md:bg-Sidepar_taps hover:bg-blue_primary active:bg-blue_primary transition-colors"
          >
            <div className=" bg-white bg-opacity-20  p-2 w-10 h-10 rounded-full flex items-center justify-center">
              <img src={balance} alt="" />
            </div>
            Account Balance
          </Link>
          <Link
            onClick={() => setActive(false)}
            to="/profile/purchase-history"
            className="flex items-center px-7 gap-2   py-6 md:py-4 bg-blue_primary  md:bg-Sidepar_taps hover:bg-blue_primary active:bg-blue_primary transition-colors"
          >
            <div className=" bg-white bg-opacity-20  p-2 w-10 h-10 rounded-full flex items-center justify-center">
              <img src={history} alt="" />
            </div>
            Purchase History
          </Link>
          <Link
            onClick={() => setActive(false)}
            to="/profile/activity-log"
            className="flex items-center px-7 py-6 md:py-4 gap-2  bg-blue_primary  md:bg-Sidepar_taps hover:bg-blue_primary active:bg-blue_primary transition-colors"
          >
            <div className=" bg-white bg-opacity-20  p-2 w-10 h-10 rounded-full flex items-center justify-center">
              <img src={activity} alt="" />
            </div>
            Activity Log
          </Link>
          <button
            onClick={handelLogOut}
            className="flex items-center px-7 py-6 md:py-4  gap-2  bg-blue_primary  md:bg-Sidepar_taps hover:bg-blue_primary active:bg-blue_primary transition-colors"
          >
            <div className=" bg-white bg-opacity-20  p-2 w-10 h-10 rounded-full flex items-center justify-center">
              <img src={logouticon} alt="" className=" w-5" />
            </div>
            Log Out
          </button>
        </div>
      </div>
    </div>
  )
}

export default Sidepar
