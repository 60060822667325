import axios from 'axios'

const getSessions = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}api/Activities`,
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    return response.data.data
  } catch (error) {
    throw error
  }
}

export default getSessions
