import React, { useState, useEffect, useCallback } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import defaultimafe from '../../assets/images/Deafaultgame.png'

const Slider = ({ images }) => {
  const [index, setIndex] = useState(0)

  const gotoSlide = useCallback(
    (newIndex) => {
      if (newIndex < 0) {
        newIndex = images.length - 1
      } else if (newIndex >= images.length) {
        newIndex = 0
      }
      setIndex(newIndex)
    },
    [images.length]
  )

  useEffect(() => {
    const timer = setInterval(() => {
      gotoSlide(index + 1)
    }, 3000)

    return () => clearInterval(timer)
  }, [index, gotoSlide])

  const variants = {
    enter: {
      opacity: 0,
    },
    center: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  }

  const swipeConfidenceThreshold = 10000
  const swipePower = (offset, velocity) => Math.abs(offset) * velocity

  return (
    <div
      style={{ boxShadow: '10px 10px 20px 1px rgba(34, 33, 33, 0.25)' }}
      className="bg-black_header_2/50 relative flex flex-col justify-end w-full h-[500px] overflow-hidden md:rounded-2xl"
    >
      <AnimatePresence initial={false} custom={index}>
        <motion.div
          key={index}
          custom={index}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            opacity: { duration: 0.5 },
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x)

            if (swipe < -swipeConfidenceThreshold) {
              gotoSlide(index + 1)
            } else if (swipe > swipeConfidenceThreshold) {
              gotoSlide(index - 1)
            }
          }}
          style={{
            backgroundImage: `url(${images[index] || defaultimafe})`,
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
          className="bg-center bg-cover"
        />
      </AnimatePresence>
      <div className="flex w-full h-5 pb-4 z-20 justify-center items-center gap-4">
        {images.map((_, imgIndex) => (
          <div
            key={imgIndex}
            onClick={() => {
              gotoSlide(imgIndex)
            }}
            className={`hover:scale-110 duration-150 cursor-pointer ${
              imgIndex === index ? 'bg-white' : 'bg-white/50'
            } w-3 h-3 rounded-full`}
          ></div>
        ))}
      </div>
    </div>
  )
}

export default Slider
