import { useRef, useEffect } from 'react'
import { motion } from 'framer-motion'
import x from '../../assets/icons/close.svg'

const CompliantPopup = ({ complaint, active, close }) => {
  const popupRef = useRef(null)

  const formatDate = (dateString) => {
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' }
    const date = new Date(dateString)
    return date.toLocaleDateString('en-GB', options)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        close()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popupRef, close])

  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [active])
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="fixed inset-0 flex   items-center bg-black_header_1/25 justify-center z-50 bg-black bg-opacity-50"
    >
      <motion.div
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.8 }}
        transition={{ duration: 0.3 }}
        ref={popupRef}
        className=" relative bg-blue_primary flex gap-4  flex-col justify-center items-center rounded-lg p-5 pt w-[90vw] md:w-1/2  shadow-lg"
      >
        <div className=" absolute top-3 right-3 w-full flex justify-end items-center">
          <img onClick={close} className="cursor-pointer" src={x} alt="" />
        </div>
        <h2 className="text-2xl text-white">Complaint Details</h2>
        <div className="text-white">
          <strong>ID:</strong> {complaint.id}
        </div>
        <div className="text-white">
          <strong>Message:</strong> {complaint.text}
        </div>
        <div className="text-white">
          <strong>Order ID:</strong> {complaint.order_id}
        </div>
        <div className="text-white">
          <strong>Date:</strong> {formatDate(complaint.created_at)}
        </div>
        <div className="text-white">
          <strong>Resspones:</strong>
          <div>{complaint.Response_text || 'No Respones Yet'}</div>
        </div>
      </motion.div>
    </motion.div>
  )
}

export default CompliantPopup
