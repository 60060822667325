import { create } from 'zustand'
import { persist } from 'zustand/middleware'

const useCurrencyStore = create(
  persist(
    (set) => ({
      currency: 'USD',
      setCurrency: (currency) => set({ currency }),
    }),
    {
      name: 'currency-storage',
      getStorage: () => localStorage,
    }
  )
)

export default useCurrencyStore
