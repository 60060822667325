import { createContext, useContext, useState, useEffect } from 'react'
import authorize from '../apis/authorize'

const AuthContext = createContext({})

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return !!localStorage.getItem('isLogedIn')
  })

  const [user, setUser] = useState(() => {
    const localUser = localStorage.getItem('user')
    return localUser ? JSON.parse(localUser) : null
  })

  const [balance, setBalance] = useState(() => {
    const localBalance = localStorage.getItem('balance')
    return localBalance ? parseFloat(localBalance) : 0
  })

  useEffect(() => {
    if (isAuthenticated) {
      localStorage.setItem('isLogedIn', 'true')
      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('balance', balance)
    } else {
      localStorage.removeItem('isLogedIn')
      localStorage.removeItem('user')
      localStorage.removeItem('balance')
    }
  }, [isAuthenticated, user, balance])

  useEffect(() => {
    const verifySession = async () => {
      try {
        const sessions = await authorize()
        if (!sessions) {
          logout()
        }
      } catch (error) {
        console.error('Failed to fetch admin sessions:', error)
        logout()
      }
    }

    if (isAuthenticated) {
      verifySession()
    }
  }, [isAuthenticated])

  const login = (userData) => {
    setUser(userData)
    setIsAuthenticated(true)
  }

  const logout = () => {
    setUser(null)
    setIsAuthenticated(false)
    setBalance(0)
  }

  const updateBalance = (newBalance) => {
    setBalance(newBalance)
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        balance,
        login,
        logout,
        updateBalance,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
