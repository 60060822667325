import React, { useState } from 'react'
import search from '../../assets/icons/search.svg'

import { useNavigate } from 'react-router-dom'

const Search = () => {
  const navigate = useNavigate()
  const [searchWord, setSearchWord] = useState('')

  const handelSearch = (e) => {
    e.preventDefault()
    navigate(`search/${searchWord}`, { replace: true })
  }

  return (
    <div className=" w-full flex justify-center items-center gap-[2px] ">
      <form className="w-full " onSubmit={handelSearch}>
        <div className=" bg-white gap-2  text-gray h-12 rounded-full p-2 flex justify-center items-center  ">
          <button type="submit">
            <img src={search} alt="search" />
          </button>
          <input
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
            type="text"
            placeholder="search on our store"
            className=" bg-transparent focus:outline-none  w-full h-12"
          />
        </div>
      </form>
    </div>
  )
}

export default Search
