import axios from 'axios'

const buyDeptBalance = async (data) => {
  console.log('data', data)
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}api/requestBalanceInDebt`,
      {
        amount: `${data}`,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export default buyDeptBalance
