import React, { useEffect, useRef, useState } from 'react'
import x from '../../assets/icons/close.svg'
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet'
import { motion } from 'framer-motion'

const LocationPicker = ({ setLocation }) => {
  const [markerPosition, setMarkerPosition] = useState(null)

  const LocationMarker = () => {
    useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng
        setMarkerPosition([lat, lng])
        setLocation({ lat, lng })
      },
    })

    return markerPosition ? <Marker position={markerPosition} /> : null
  }

  return (
    <MapContainer
      center={[51.505, -0.09]}
      zoom={13}
      style={{ height: '400px', width: '100%' }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <LocationMarker />
    </MapContainer>
  )
}

const MapPopup = ({ close, setLocation }) => {
  const mapref = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mapref.current && !mapref.current.contains(event.target)) {
        close()
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [mapref, close])

  return (
    <motion.div
      className="fixed inset-0 bg-black_header_1/25 w-screen h-screen grid place-items-center z-[50]"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        ref={mapref}
        className=" bg-blue_primary w-[90vw] md:w-1/2 p-4 -z-50 rounded-lg shadow-lg"
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.8 }}
        transition={{ duration: 0.3 }}
      >
        <div className=" w-full flex justify-end">
          <img
            src={x}
            alt=""
            onClick={close}
            className=" mb-2 cursor-pointer "
          />
        </div>
        <LocationPicker setLocation={setLocation} />
      </motion.div>
    </motion.div>
  )
}

export default MapPopup
