import { motion, useScroll, useTransform } from 'framer-motion'

const FloatingImage = ({ delay, img, top, left, floatDirection = 'left' }) => {
  const { scrollYProgress } = useScroll()

  // Dynamic x-axis transformation based on the direction
  const xEndPosition = floatDirection === 'left' ? -300 : 50 // Moves 300px to the left or right
  const yEndPosition = floatDirection === 'left' ? 500 : 800 // Moves 300px to the left or right

  const y = useTransform(scrollYProgress, [0, 1], [0, yEndPosition]) // Vertical movement
  const x = useTransform(scrollYProgress, [0, 1], [0, xEndPosition]) // Horizontal movement based on direction

  return (
    <motion.div
      className="absolute w-20 md:w-32 lg:w-40 xl:w-56 z-0 transition-opacity duration-500 "
      style={{
        x,
        y,
        top: `${top}%`,
        left: `${left}%`, // Using inline styles for dynamic positioning
      }}
      initial={{ opacity: 0 }} // Start with invisible image
      animate={{ opacity: 0.8 }} // Animate to fully visible
      transition={{
        delay: delay, // Use the delay prop to delay the start of the animation
        duration: 0.5, // Duration of the opacity animation
      }}
    >
      <img src={img} alt="Floating" />
    </motion.div>
  )
}

export default FloatingImage
