import { motion } from 'framer-motion'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useCart } from '../../hooks/CartContext'
import getCurrancy from '../../apis/getCurrancy'
import currencyicon from '../../assets/icons/currencyDark.svg'

const OptionsPopup = ({ active, close }) => {
  const popupRef = useRef(null)
  const mopref = useRef(null)
  const [currencies, setCurrencies] = useState([])
  const { changeCurrency, currency } = useCart()

  const fetchCurencies = useCallback(async () => {
    try {
      const Currency = await getCurrancy()
      setCurrencies(Currency)
    } catch (error) {
      console.error('Failed to fetch currencies', error)
    }
  }, [])

  useEffect(() => {
    fetchCurencies()
  }, [fetchCurencies])

  const handleCurrencyChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex]
    const code = selectedOption.value
    const amount = parseFloat(selectedOption.getAttribute('data-amount'))
    const selectedCurrency = { code, amount }

    changeCurrency(selectedCurrency)
  }

  useEffect(() => {
    const handleBodyOverflow = () => {
      document.body.style.overflow = active ? 'hidden' : 'auto'
    }

    handleBodyOverflow()

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [active])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        mopref.current &&
        !mopref.current.contains(event.target)
      ) {
        close()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [close])

  return (
    <motion.div
      className="fixed inset-0 bg-overlay/30 w-screen  z-50 h-screen grid "
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        ref={popupRef}
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        transition={{ duration: 0.3 }}
        className=" hidden absolute top-[10%] left-[60%]  gap-3 p-4 py-6 w-[15vw]  z-40 bg-white/30 rounded-xl md:flex flex-col justify-center items-strat "
      >
        <div className=" text-sm text-white">Currency</div>

        <div className=" bg-white p-2 rounded-lg flex justify-between ">
          <div className=" w-4/6 flex justify-start items-center gap-2">
            <img src={currencyicon} alt="lang" />
            <div className=" text-sm">Currency</div>
          </div>
          <select
            value={`${currency.code}|${currency.amount}`}
            onChange={(e) => handleCurrencyChange(e)}
            className="select w-2/6 text-xs focus:outline-none  overflow-auto"
          >
            <option
              className="text-white hover:bg-yellow hover:text-black_header_3 text-xs bg-blue_primary "
              value={currency.code}
            >{`${currency.code}`}</option>
            {currencies
              .filter((option) => option.code !== currency.code)
              .map((option) => (
                <option
                  className="text-white hover:bg-yellow hover:text-black_header_3 text-xs bg-blue_primary "
                  key={option.code}
                  value={option.code}
                  data-amount={option.amount}
                >
                  {option.code}
                </option>
              ))}
          </select>
        </div>
      </motion.div>

      <motion.div
        ref={mopref}
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -50 }}
        transition={{ duration: 0.3 }}
        className=" md:hidden fixed h-screen gap-3 p-4  w-[60%] z-40 bg-blue_primary  flex flex-col justify-start items-strat "
      >
        <div className=" text-sm text-white">Currency </div>

        <div className=" bg-white p-2 rounded-lg flex justify-between ">
          <div className=" w-4/6 flex justify-start items-center gap-2">
            <img src={currencyicon} alt="lang" />
            <div className=" text-sm">Currency</div>
          </div>
          <select
            value={`${currency.code}|${currency.amount}`}
            onChange={(e) => handleCurrencyChange(e)}
            className="select w-2/6 text-xs focus:outline-none bg-transparent  overflow-auto"
          >
            <option
              className="text-white hover:bg-yellow hover:text-black_header_3 text-xs bg-blue_primary "
              value={currency.code}
            >{`${currency.code}`}</option>
            {currencies
              .filter((option) => option.code !== currency.code)
              .map((option) => (
                <option
                  className="text-white hover:bg-yellow hover:text-black_header_3 text-xs bg-blue_primary "
                  key={option.code}
                  value={option.code}
                  data-amount={option.amount}
                >
                  {option.code}
                </option>
              ))}
          </select>
        </div>
      </motion.div>
    </motion.div>
  )
}

export default OptionsPopup
