import trash from '../../assets/icons/delet.svg'
import plus from '../../assets/icons/plus.svg'
import defaultimg from '../../assets//images/defaultPackageImage.webp'
import mines from '../../assets/icons/mines.svg'
import { useCart } from '../../hooks/CartContext'
import { useEffect, useState } from 'react'

const CartPackage = ({ pack, currency }) => {
  const { removeFromCart, decreseQuantity, itemExists, increasQuantity } =
    useCart()
  const [exsist, setExsist] = useState(false)
  const handelremove = (id) => {
    removeFromCart(id)
    setExsist(false)
  }

  useEffect(() => {
    setExsist(itemExists(pack.data[0].id))
  }, [pack.data, itemExists])

  const imageUrl = pack.data[0]?.image.endsWith('/package')
    ? defaultimg
    : pack.data[0]?.image

  return (
    <div
      className={`${
        exsist ? '' : 'hidden'
      } min-h-[100px] max-h-[120px] w-full md:min-h-[130px] bg-package rounded-lg flex overflow-hidden`}
    >
      <div className=" p-2 md:p-0 w-10/12 md:w-11/12 flex-grow px-2 md:py-4 md:px-8 flex gap-4 items-stretch">
        <div className=" h-full  aspect-[4/5] ">
          <img
            className=" w-full rounded-md h-full object-cover"
            src={imageUrl}
            alt="Game"
          />
        </div>

        <div className="w-[75%] gap-4 flex h-full flex-col md:flex-row justify-center md:justify-between items-start md:items-center">
          <div className="  flex flex-col items-start justify-center ">
            <span className=" text-white text-sm md:text-2xl">
              {pack.data[0].title}
            </span>
          </div>
          <div className=" flex justify-center items-center text-yellow  md:text-2xl">
            {pack.data[0].price_client * currency.amount}
            <sapn className=" text-sm pl-1">{currency.code} </sapn>
          </div>
        </div>

        <div className=" flex items-center justify-center gap-1 md:gap-3">
          <button
            className="w-8 h-8"
            onClick={() => increasQuantity(pack.data[0].id)}
          >
            <img src={plus} alt="" />
          </button>
          <span className=" text-white  md:text-2xl">{pack.quantity}</span>
          <button
            className="w-8 h-8"
            onClick={() => {
              decreseQuantity(pack.data[0].id)
            }}
          >
            <img src={mines} alt="" />
          </button>
        </div>
      </div>
      <button
        onClick={() => handelremove(pack.data[0].id)}
        className=" w-1/12 md:w-1/12 flex-grow flex justify-center items-stretch bg-red"
      >
        <img className="self-center" src={trash} alt="Delete" />
      </button>
    </div>
  )
}

export default CartPackage
