import React, { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import searchCard from '../apis/searchCards'
import Loading from '../components/shared/Loading'
import homebg from '../assets/backgrounds/home.png'
import subCategoryImg from '../assets/images/subCategory.jpg'
const Search = () => {
  const { searshWord } = useParams()
  const [cards, setCards] = useState([])
  const [loading, setLoading] = useState(true)
  const getCards = useCallback(async () => {
    try {
      const cards = await searchCard(searshWord)
      console.log(cards)

      setCards(cards)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }, [searshWord])

  useEffect(() => {
    getCards()
  }, [getCards])

  if (loading) {
    return <Loading />
  }

  return (
    <div className="bg-gradient-to-br  from-gradient_dark to-blue_header_3 overflow-x-hidden min-h-screen ">
      <div
        className="bg-cover bg-center  flex flex-col justify-center items-center   gap-10 py-2 pb-20  min-h-screen "
        style={{ backgroundImage: `url(${homebg})` }}
      >
        {cards.length === 0 ? (
          <div className=" capitalize text-yellow flex flex-col justify-center items-center gap-3 text-2xl">
            no Cards were found for {searshWord}
            <Link className=" underline text-white text-xs" to={'/'}>
              go back home
            </Link>
          </div>
        ) : (
          <div className=" w-full flex flex-col   py-10 md:py-16 lg:py-20 gap-8  md:gap-10 h-screen">
            <div className=" px-8 capitalize text-yellow text-2xl">
              search results for {searshWord}
            </div>
            <div className="flex flex-wrap justify-center items-center">
              {cards.map((card) => (
                <Link
                  to={`/card/${card.id}`}
                  key={card.id}
                  className=" cursor-pointer min-w-[160px] hover:scale-105 duration-300 w-[30%] rounded-2xl  overflow-hidden flex flex-col justify-end items-center md:max-w-[200px] aspect-[10/16]"
                  style={{
                    backgroundImage: `url(${card.image || subCategoryImg})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                  }}
                >
                  <div className=" bg-black_header_3/60 text-xs sm:text-sm  text-white p-4 w-full text-center ">
                    {String(card.title.split(' ').slice(0, 1)).toLowerCase() ===
                    'buy'
                      ? card.title.split(' ').slice(1).join(' ')
                      : card.title.split(' ').slice(0).join(' ')}
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Search
