import cart from '../../assets/icons/cart.svg'

const MyCart = ({ openCart, cartQuantity }) => {
  return (
    <div className=" flex flex-col ">
      <div
        className=" flex justify-center items-center gap-2 cursor-pointer"
        onClick={openCart}
      >
        <div className="w-10 md:w-12 bg-white_opacity rounded-full aspect-square p-2 md:p-3 relative">
          {cartQuantity !== 0 && (
            <div
              style={{ lineHeight: '0px' }}
              className="flex items-center justify-center rounded-full top-2 right-0 absolute p-1 aspect-square bg-red text-white text-xs"
            >
              {cartQuantity}
            </div>
          )}
          <img src={cart} alt="options" />
        </div>
        <div className=" text-white hidden lg:flex">My Cart</div>
      </div>
    </div>
  )
}

export default MyCart
