import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useAuthStore = create(
  persist(
    (set, get) => ({
      user: null,
      verificationCode: null,
      isLoggedIn: false,
      logIn: (user, verificationCode) => {
        set({
          user,
          verificationCode,
          isLoggedIn: true,
        })
      },
      logOut: () => {
        set({
          user: null,
          verificationCode: null,
          isLoggedIn: false,
        })
      },
    }),
    {
      name: 'auth-storage',
      getStorage: () => sessionStorage,
    }
  )
)

export default useAuthStore
