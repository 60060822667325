import React from 'react'
import defaultImage from '../../assets/images/Deafaultgame.png'
import { Link } from 'react-router-dom'
const HeroCards = ({ card }) => {
  return (
    <Link
      to={`card/${card?.id}`}
      className=" hover:scale-105 duration-300 w-[30%] rounded-2xl overflow-hidden flex flex-col justify-between items-start xl:max-w-[160px] aspect-[10/16]"
      style={{
        backgroundImage: `url(${card?.image || defaultImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className=" bg-blue_primary text-white px-3 py-1 rounded-br-2xl ">
        50%
      </div>
      <div className=" text-white bg-yellow w-full flex justify-between p-2 items-center ">
        <div className=" line-through"> 100$ </div>
        <div> 50$ </div>
      </div>
    </Link>
  )
}

export default HeroCards
