import { AnimatePresence, motion } from 'framer-motion'
import sidepareIcon from '../../assets/icons/sidepar.svg'
import React from 'react'

const DescriptionPopup = ({ text, close, open }) => {
  return (
    <AnimatePresence>
      {open && (
        <motion.div
          initial={{ opacity: 0, y: '100%' }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: '100%' }}
          transition={{ duration: 0.3 }}
        >
          <div className="z-20 fixed inset-0 gap-5 px-5 w-screen h-screen flex flex-col py-8   md:hidden bg-blue_primary p-2 ">
            <div className="w-full flex items-start justify-start">
              <img onClick={close} src={sidepareIcon} alt="" />{' '}
            </div>
            <div className="w-full flex items-center gap-3">
              <h2 className="text-white text-xl  "> Description</h2>
              <div className=" flex md:hidden h-[1px] bg-white w-full"></div>
            </div>
            <div className=" text-white">{text}</div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default DescriptionPopup
