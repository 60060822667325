import { useState } from 'react'
import PasswordInput from '../../components/shared/PasswordInput'
import ToggleSwitch from '../../components/layouts/Toggleswitch'
import shield from '../../assets/icons/shield.svg'
import changePassword from '../../apis/changePassword'

const Security = () => {
  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    confermPassword: '',
  })

  const [twoFactor, setTwoFactor] = useState(false)
  const [error, setError] = useState('')
  const [sucsess, setSucsess] = useState('')
  const [loading, setLoading] = useState(false)

  const handelTowFactor = () => {
    setTwoFactor(!twoFactor)
  }

  const handleInputChange = (event) => {
    setError('')
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const handelSubmit = async (event) => {
    event.preventDefault()
    if (formData.newPassword !== formData.confermPassword) {
      setError(
        'the new password and the password confermation are not the same'
      )
      return
    }

    if (formData.newPassword === formData.currentPassword) {
      setError(
        "the new password and the current password can't be the same  the same"
      )
      return
    }

    if (formData.newPassword.length <= 4) {
      setError('Password must be more than 4 characters.')

      return
    }
    if (
      !/\d/.test(formData.newPassword) ||
      !/[a-zA-Z]/.test(formData.newPassword)
    ) {
      setError('Password must contain both letters and numbers.')
      return
    }

    try {
      setLoading(true)
      await changePassword(formData)
      setSucsess('password changed sucsessfully')
      setFormData({ currentPassword: '', newPassword: '', confermPassword: '' })
      setTimeout(() => setSucsess(''), 1500)
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <div className="flex flex-col md:flex-row w-full overflow-x-hidden ">
        <div className="  text-white flex flex-col gap-8  px-1 py-6 w-full md:w-1/2">
          <h1 className="text-2xl hidden md:flex font-normal  text-white ">
            Security
          </h1>
          <form
            onSubmit={handelSubmit}
            className="flex  flex-col w-full md:w-[79%] px-1  gap-8"
          >
            <PasswordInput
              name="currentPassword"
              placeholder={'Current Password'}
              value={formData.currentPassword}
              Change={handleInputChange}
              className={`py-3 text-xl px-5 shadow bg-white rounded-full flex items-center gap-2`}
            />
            <PasswordInput
              name="newPassword"
              placeholder={'New Password'}
              value={formData.newPassword}
              Change={handleInputChange}
              className={`py-3 text-xl px-5 shadow bg-white rounded-full flex items-center gap-2`}
            />
            <PasswordInput
              name="confermPassword"
              placeholder={'Conferm Password'}
              value={formData.confermPassword}
              Change={handleInputChange}
              className={`py-3 text-xl px-5 shadow bg-white rounded-full flex items-center gap-2`}
            />

            {error && (
              <div className=" text-red bg-[#F6C0C0] text-sm text-center rounded-md">
                {error}
              </div>
            )}
            {sucsess && (
              <div className=" text-white bg-yellow text-sm py-1 text-center rounded-md">
                {sucsess}
              </div>
            )}

            {(formData.currentPassword ||
              formData.newPassword ||
              formData.confermPassword) && (
              <button
                disabled={
                  !formData.currentPassword ||
                  !formData.newPassword ||
                  !formData.confermPassword ||
                  loading
                    ? true
                    : false
                }
                type="submit"
                className=" flex disabled:bg-yellow/70 bg-[#FFC20F] hover:bg-yellow  text-white text-lg w-full font-bold py-3 px-8 rounded-full shadow-lg transition duration-300"
              >
                {loading && (
                  <div
                    style={{
                      borderColor: `yellow transparent yellow transparent`,
                    }}
                    className=" border-2 w-1/12 aspect-square animate-spin rounded-full "
                  ></div>
                )}
                <span className={`${loading ? 'w-11/12' : 'w-full'}`}>
                  Change Password
                </span>
              </button>
            )}
          </form>
        </div>
        <div className="   text-white  px-1 py-6  w-full md:w-1/2">
          <div className=" flex flex-col gap-8 ">
            <h1 className=" text-white text-start text-2xl font-normal">
              Linked Accounts
            </h1>
            <form>
              <div className="flex flex-col md:flex-row pr-3 gap-4 ">
                <div className="flex w-full md:w-4/5">
                  <img alt="" src={shield} className=" h-12 w-12  mr-2" />
                  <span className="  text-white text-l font-regular">
                    You Can Use Two-Factor Authentication For Your Security
                  </span>
                </div>
                <div className="flex w-2/5 flex-row items-center gap-2">
                  <span className=" text-white  text-xl font-semibold">
                    OFF
                  </span>
                  <ToggleSwitch
                    className=""
                    setswitch={handelTowFactor}
                    name="twoFactor"
                    checked={twoFactor}
                  />
                  <span className=" text-white  text-xl font-semibold">ON</span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Security
