import React from 'react'

const Heading = ({ title }) => {
  return (
    <div className=" w-full flex items-center justify-stretch  md:justify-center md:px-5 lg:px-0  py-10 ">
      <div className=" font-bold w-[35%]  md:w-1/2 sm:text-xl sm:w-1/3 lg:w-1/6  md:text-center md:border-yellow p-4 md:border-[1px] lg:text-2xl text-white md:text-yellow  ">
        {title}
      </div>
      <div className="  w-3/5 md:w-1/2 sm:w-2/3 lg:w-9/12 h-[1px] bg-white md:bg-yellow "></div>
      <div className=" text-white md:hidden text-2xl">{'>'}</div>
    </div>
  )
}

export default Heading
