import { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import homebg from '../assets/backgrounds/home.png'
import getSubCategories from '../apis/getSubCategories'
import Loading from '../components/shared/Loading'
import NotFound from './NotFound'

const Category = () => {
  const { subCategoryId, categoryId } = useParams()

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (subCategoryId === categoryId) return
        const result = await getSubCategories(subCategoryId)
        setData(result)
      } catch (error) {
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [subCategoryId, categoryId])

  if (loading) {
    return <Loading />
  }

  if (!data || data.length === 0) {
    return <NotFound />
  }

  const isSubcategories =
    data[0].hasOwnProperty('id') && !data[0].hasOwnProperty('cards')

  return (
    <div className=" bg-gradient-to-br  from-gradient_dark to-blue_header_3 overflow-x-hidden min-h-screen ">
      <div
        className="bg-cover bg-center  flex justify-center items-center   gap-10 py-2 pb-20  min-h-screen "
        style={{ backgroundImage: `url(${homebg})` }}
      >
        {isSubcategories ? (
          <div className="min-w-[160px]  w-full flex flex-wrap justify-center items-center py-10 md:py-16 lg:py-20 gap-8  md:gap-10 h-screen">
            {data.map((sub) => (
              <Link
                to={`subcategory/${sub.id}`}
                key={sub.id}
                className=" cursor-pointer hover:scale-105 duration-300 w-[30%] rounded-2xl  overflow-hidden flex flex-col justify-end items-center md:max-w-[200px] aspect-[10/16]"
                style={{
                  backgroundImage: `url(${sub.image})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              >
                <div className=" bg-black_header_3/60 text-white p-4 w-full text-center ">
                  {' '}
                  {sub.title}{' '}
                </div>
              </Link>
            ))}
          </div>
        ) : (
          <div className=" w-full flex flex-wrap justify-center items-center py-10 md:py-16 lg:py-20 gap-8  md:gap-10 h-screen">
            {data[0].cards.map((item) => (
              <Link
                to={`card/${item.id}`}
                key={item.id}
                className=" min-w-[160px] cursor-pointer hover:scale-105 duration-300 w-[30%] rounded-2xl  overflow-hidden flex flex-col justify-end items-center md:max-w-[200px] aspect-[10/16]"
                style={{
                  backgroundImage: `url(${item.image})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              >
                <div className=" bg-black_header_3/60 text-sm text-white p-4 w-full text-center ">
                  {String(item.title.split(' ').slice(0, 1)).toLowerCase() ===
                  'buy'
                    ? item.title.split(' ').slice(1, 4).join(' ')
                    : item.title.split(' ').slice(0, 3).join(' ')}
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
export default Category
