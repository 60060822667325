import { useState } from 'react'
import plus from '../../assets/icons/add.svg'
import mines from '../../assets/icons/decrease.svg'
import pasket from '../../assets/icons/pasket.svg'
import defaultImge from '../../assets/images/defaultPackageImage.webp'
import { useCart } from '../../hooks/CartContext'

const CardPackage = ({ pkg }) => {
  const [quantity, setQuantity] = useState(1)
  const { addToCart, currency, openCart } = useCart()

  const price = pkg.price_client || pkg.price_pos_client

  const handleDecrease = () => {
    if (quantity <= 1) return
    setQuantity(quantity - 1)
  }

  const addToCartHandler = () => {
    addToCart(pkg.id, quantity, pkg.image)
    setQuantity(1)
  }

  const handleBuy = () => {
    addToCart(pkg.id, quantity, pkg.image)
    setQuantity(1)
    openCart()
  }

  // Determine the image URL to use
  const imageUrl = pkg?.image.endsWith('/package') ? defaultImge : pkg?.image

  return (
    <>
      <div className="w-full h-full p-1">
        <div className="w-full h-full bg-blue_primary/75 rounded flex items-end justify-between overflow-hidden">
          <div className="w-[27%]  h-full aspect-[4/5] p-2">
            <img
              src={imageUrl}
              className="w-full rounded-md h-full object-cover"
              alt={pkg.title}
            />
          </div>
          <div className="flex flex-col justify-end h-full items-start w-[73%]">
            <div className="pt-1 pr-1 text-white w-full flex gap-2 justify-between items-center">
              <span className="text-xs">{pkg.title}</span>
              <span className="text-yellow flex flex-nowrap items-end">
                {parseFloat(price * currency.amount).toFixed(1)}
                <span className="text-[10px]"> {currency.code}</span>
              </span>
            </div>
            <div className="text-white flex justify-center gap-1 items-center py-2">
              <button onClick={() => setQuantity(quantity + 1)} className="">
                <img src={plus} alt="increase quantity" />
              </button>
              <span>{quantity}</span>
              <button onClick={handleDecrease} className="">
                <img src={mines} alt="decrease quantity" />
              </button>
            </div>
            <div className="w-full flex justify-between">
              <button onClick={handleBuy} className="text-yellow">
                Buy Now
              </button>
              <button
                onClick={addToCartHandler}
                className="p-2 px-4 rounded-tl-full bg-yellow flex text-white"
              >
                <img src={pasket} alt="add to cart" /> Add To Cart
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CardPackage
