const ToggleSwitch = ({ setswitch, name, checked }) => {
  return (
    <label className="relative inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        className="sr-only"
        onChange={setswitch}
        name={name}
        checked={checked}
      />
      <span
        className={`block  h-[1.5rem] sm:h-[2rem] w-12 sm:w-[4.5rem] rounded-full shadow-inner after:content-[''] after:absolute after:top-[2px] sm:after:top-[2px] after:left-1 after:bg-switches after:rounded-full after:w-5 after:h-5  sm:after:h-7 sm:after:w-7 after:transition-all ${
          checked
            ? ' bg-white after:translate-x-5 sm:after:translate-x-[2.25rem]'
            : ' after:translate-x-[0%] bg-white/50 '
        }`}
      ></span>
    </label>
  )
}

export default ToggleSwitch
