import axios from 'axios'

const editProfileUser = async (updatedData) => {
  const formData = new FormData()

  Object.keys(updatedData).forEach((key) => {
    formData.append(key, updatedData[key])
  })

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}api/editProfileUser`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      }
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export default editProfileUser
