import { Link } from 'react-router-dom'
import homebg from '../assets/backgrounds/home.png'

const NotFound = () => {
  return (
    <div className=" bg-gradient-to-br  from-gradient_dark to-blue_header_3 overflow-x-hidden min-h-screen ">
      <div
        className="bg-cover bg-center  flex flex-col justify-center items-center   gap-10 py-2 pb-20  min-h-screen "
        style={{ backgroundImage: `url(${homebg})` }}
      >
        <div className=" text-white  md:text-2xl text-center ">
          Sorry We Couldn't Find What Are You Looking For
        </div>
        <Link
          className=" hover:scale-110 duration-300 underline text-yellow"
          to={'/'}
        >
          Go Back Home
        </Link>
      </div>
    </div>
  )
}

export default NotFound
