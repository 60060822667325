import axios from 'axios'
import generateDeviceToken from '../hooks/generateDeviceToken'

const signupUser = async (userData) => {
  const device = navigator.userAgent
  const deviceToken = generateDeviceToken()
  const singupData = {
    ...userData,
    device_token: deviceToken,
    device: device,
    account_balance: '0',
    is_active: '1',
    type: 'normal',
    ip_country: '185.107.56.166',
  }

  const formData = new FormData()
  Object.keys(singupData).forEach((key) => {
    formData.append(key, singupData[key])
  })

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}api/register`,
      formData,
      {
        withCredentials: true,
      }
    )

    return response.data.data
  } catch (error) {
    throw error
  }
}

export default signupUser
