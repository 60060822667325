import axios from 'axios'

const logoutUser = async (user, cart) => {
  try {
    const formData = new FormData()
    formData.append('email', user.email)
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}api/logout${
        cart.packages_id.length !== 0
          ? `?packages_id=[${[...cart.packages_id]}]&quantity=[${[
              ...cart.packages_quantity,
            ]}]`
          : ''
      }`,
      formData,
      {
        withCredentials: true,
      }
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export default logoutUser
