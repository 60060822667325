import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import addComplaint from '../../apis/addComplaint'

const AddComplaint = () => {
  const { orderId } = useParams()
  const [complaint, setComplaint] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [succsess, setSuccsess] = useState('')

  useEffect(() => {
    if (succsess || error) {
      const timer = setTimeout(() => {
        setSuccsess('')
        setError('')
      }, 3000)
      return () => clearTimeout(timer)
    }
  }, [succsess, error])

  const handelAddComplaint = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const res = await addComplaint(orderId, complaint)
      setSuccsess(res.message)
    } catch (error) {
      setError(error.response.data.message)
    } finally {
      setLoading(false)
    }
  }
  return (
    <div className="w-full  p-3 flex flex-col relative">
      <h1 className="text-2xl hidden md:flex font-normal mb-8 text-white">
        Add New Complaint for Order {orderId}
      </h1>
      <div className="flex flex-col gap-3  ">
        <form
          onSubmit={handelAddComplaint}
          className=" flex w-full flex-col gap-8 justify-center items-center"
        >
          <div className=" bg-white h-80 rounded-2xl w-full p-4">
            <textarea
              name="complaint"
              value={complaint}
              onChange={(e) => setComplaint(e.target.value)}
              placeholder="complaint"
              className=" w-full h-full focus:outline-none"
            />
          </div>
          {error && (
            <div className=" px-6 flex justify-center items-center py-1 bg-red/65 rounded-xl">
              <div className=" text-white">{error}</div>
            </div>
          )}
          {succsess && (
            <div className=" px-6 flex justify-center items-center py-1 bg-green rounded-xl">
              <div className=" text-white">{succsess}</div>
            </div>
          )}

          <button
            disabled={loading}
            type="submit"
            className="flex justify-center items-center disabled:bg-yellow/70 bg-[#FFC20F] hover:bg-yellow  text-white text-lg w-full md:w-1/2 font-bold py-3 px-8 rounded-full shadow-lg transition duration-300"
          >
            {loading && (
              <div
                style={{ borderColor: `yellow transparent yellow transparent` }}
                className=" border-2 w-1/12 aspect-square animate-spin rounded-full "
              ></div>
            )}
            <span className={`${loading ? 'w-11/12' : 'w-full'}`}>
              Send Complaint
            </span>
          </button>
        </form>
      </div>
    </div>
  )
}

export default AddComplaint
