import React from 'react'

const Loading = () => {
  return (
    <div className=" fixed grid place-items-center inset-0 bg-gradient-to-br  from-gradient_dark to-blue_header_3 w-screen h-screen">
      <div>
        {/* Spinner */}
        <div
          className="spinner-border  animate-spin inline-block w-12 h-12 border-4 rounded-full"
          role="status"
          style={{
            borderColor: 'yellow transparent yellow transparent',
          }}
        ></div>

        {/* Loading Text */}
        <div className="mt-4 text-base font-semibold text-yellow ">
          Loading...
        </div>
      </div>
    </div>
  )
}

export default Loading
