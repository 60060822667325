import React, { useCallback, useEffect, useState } from 'react'
import Gblack from '../../assets/icons/Gblack.svg'
import profileblack from '../../assets/icons/profileblack.svg'
import phoneblack from '../../assets/icons/phoneblack.svg'
import ToggleSwitch from '../../components/layouts/Toggleswitch'
import GoogleBW from '../../assets/icons/GoogleBW.svg'
import Adress from '../../assets/icons/address.svg'
import Avatarimg from '../../assets/backgrounds/default-avatar.jpg'
import FacebookBW from '../../assets/icons/FacbookBW.svg'
import editProfileUser from '../../apis/editProfileUser'
import viewProfileUser from '../../apis/viewProfileUser'
import Loading from '../../components/shared/Loading'

const Personal = () => {
  const [user, setUser] = useState(null)
  const avatar = user?.image || Avatarimg
  const [disable, setDisable] = useState(true)
  const [loading, setLoading] = useState(false)
  const [pageLoading, setpageLoading] = useState(true)
  const [inputs, setInputs] = useState({
    userEmail: '',
    userName: '',
    userPhone: '',
    userAdress: '',
    facebook: false,
    google: false,
  })
  const [imageFile, setImageFile] = useState(null)
  const [preview, setPreview] = useState(null)

  const fetchUserData = useCallback(async () => {
    try {
      const response = await viewProfileUser()
      const userData = response.data
      setUser(userData)
      setInputs({
        userEmail: userData.email,
        userName: `${userData.first_name} ${userData.last_name}`,
        userPhone: userData.mobile || '',
        userAdress: userData.address,
        facebook: false,
        google: false,
      })
      setImageFile(
        userData.image
          ? `${process.env.REACT_APP_IMAGE_BASE_URL}${userData.image}`
          : null
      )
      setPreview(null)
    } catch (error) {
      console.error('Failed to fetch user data', error)
    } finally {
      setpageLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchUserData()
  }, [fetchUserData])

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: type === 'checkbox' ? checked : value,
    }))
  }

  const handleDisable = () => {
    setDisable(!disable)
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      setImageFile(file)
      setPreview(URL.createObjectURL(file))
      handleDisable()
    }
  }

  const handleImageClick = () => {
    document.getElementById('imageInput').click()
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const updatedData = {}
    if (inputs.userEmail !== user?.email) updatedData.email = inputs.userEmail
    if (inputs.userName !== `${user?.first_name} ${user?.last_name}`) {
      const [first_name, last_name] = inputs.userName.split(' ')
      updatedData.first_name = first_name
      updatedData.last_name = last_name
    }
    if (inputs.userPhone !== user.mobile) updatedData.mobile = inputs.userPhone
    if (inputs.userAdress !== user.address)
      updatedData.address = inputs.userAdress
    if (imageFile) {
      updatedData.images = imageFile
    }

    try {
      await editProfileUser(updatedData)
      window.location.reload()
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  if (pageLoading) {
    return <Loading />
  }

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col lg:gap-0 gap-8 lg:flex-row w-full overflow-x-hidden"
      >
        <div className="lg:w-1/2 w-full flex flex-col p-3 gap-10 lg:pr-10">
          <h1 className="text-2xl hidden md:flex font-normal text-white">
            Personal Information
          </h1>
          <div className="flex flex-col gap-8">
            <div className="relative">
              <img
                src={preview || avatar}
                alt="Profile"
                className="w-16 h-16 object-cover rounded-full cursor-pointer"
                onDoubleClick={handleImageClick}
              />
              <input
                type="file"
                id="imageInput"
                className="hidden"
                onChange={handleFileChange}
              />
              {preview && (
                <button
                  className="absolute top-0 left-0 duration-300 bg-red-500 w-16 h-16 text-2xl opacity-0 hover:opacity-100 hover:text-white rounded-full p-1 hover:bg-black_header_1/35"
                  onClick={() => {
                    setImageFile(null)
                    setPreview(null)
                  }}
                >
                  x
                </button>
              )}
            </div>

            <div className="group bg-white rounded-full p-4 flex justify-between items-center">
              <img
                alt=""
                src={Gblack}
                className="h-6 w-6 text-black_header_3 mr-2"
              />
              <input
                className="w-10/12 disabled:bg-white bg-footer/10 px-2 focus:outline-none"
                onChange={handleInputChange}
                name="userEmail"
                type="text"
                disabled={disable}
                value={inputs.userEmail}
              />
              <div
                className="group-hover:opacity-100 lg:opacity-0 group-hover:cursor-pointer transition-all duration-300"
                onClick={handleDisable}
              >
                {disable ? 'Edit' : 'Cancel'}
              </div>
            </div>

            <div className="group bg-white rounded-full p-4 flex justify-between items-center">
              <img
                alt=""
                src={profileblack}
                className="h-6 w-6 text-black_header_3 mr-2"
              />
              <input
                className="w-10/12 disabled:bg-white bg-footer/10 px-2 focus:outline-none"
                onChange={handleInputChange}
                name="userName"
                type="text"
                disabled={disable}
                value={inputs.userName}
              />
              <div
                className="group-hover:opacity-100 lg:opacity-0 group-hover:cursor-pointer transition-all duration-300"
                onClick={handleDisable}
              >
                {disable ? 'Edit' : 'Cancel'}
              </div>
            </div>

            <div className="group bg-white rounded-full p-4 flex justify-between items-center">
              <img
                alt=""
                src={phoneblack}
                className="h-6 w-6 text-black_header_3 mr-2"
              />
              <input
                className="w-10/12 disabled:bg-white bg-footer/10 px-2 focus:outline-none"
                onChange={handleInputChange}
                name="userPhone"
                type="text"
                placeholder="User Phone"
                disabled={disable}
                value={inputs.userPhone}
              />
              <div
                className="group-hover:opacity-100 lg:opacity-0 group-hover:cursor-pointer transition-all duration-300"
                onClick={handleDisable}
              >
                {disable ? 'Edit' : 'Cancel'}
              </div>
            </div>

            <div className="group bg-white rounded-full p-4 flex justify-between items-center">
              <img
                alt=""
                src={Adress}
                className="h-6 w-6 text-black_header_3 mr-2"
              />
              <input
                className="w-10/12 disabled:bg-white bg-footer/10 px-2 focus:outline-none"
                onChange={handleInputChange}
                name="userAdress"
                type="text"
                placeholder="User Address"
                disabled={disable}
                value={inputs.userAdress}
              />
              <div
                className="group-hover:opacity-100 lg:opacity-0 group-hover:cursor-pointer transition-all duration-300"
                onClick={handleDisable}
              >
                {disable ? 'Edit' : 'Cancel'}
              </div>
            </div>

            {!disable && (
              <button
                disabled={loading}
                type="submit"
                className={`${
                  loading ? ' bg-yellow/45' : 'bg-yellow'
                } px-5 py-3 text-xl font-bold text-white rounded-full overflow-hidden flex items-center`}
              >
                {loading && (
                  <div
                    style={{
                      borderColor: `yellow transparent yellow transparent`,
                    }}
                    className=" border-2 w-1/12 aspect-square animate-spin rounded-full "
                  ></div>
                )}
                <span className={`${loading ? 'w-10/12' : 'w-full'} `}>
                  Save Changes
                </span>
              </button>
            )}
          </div>
        </div>

        <div className="lg:w-1/2 md:w-full flex flex-col p-3 gap-10 lg:px-10">
          <h1 className="text-2xl font-normal text-white">Linked Accounts</h1>
          <div className="flex flex-col gap-10">
            <div className="flex w-full justify-between xl:pr-10 items-center gap-10">
              <div className="flex justify-start items-center gap-4">
                <img alt="" src={FacebookBW} className="h-12 w-12" />
                <span className="text-white text-lg sm:text-xl font-semibold">
                  Facebook
                </span>
              </div>
              <div className="flex items-center gap-2">
                <span className="text-white text-lg sm:text-xl font-semibold">
                  OFF
                </span>
                <ToggleSwitch
                  setswitch={handleInputChange}
                  name="facebook"
                  checked={inputs.facebook}
                />
                <span className="text-white text-lg sm:text-xl font-semibold">
                  ON
                </span>
              </div>
            </div>

            <div className="flex w-full justify-between xl:pr-10 items-center gap-10">
              <div className="flex justify-start items-center gap-4">
                <img alt="" src={GoogleBW} className="h-12 w-12" />
                <span className="text-white text-lg sm:text-xl font-semibold">
                  Google
                </span>
              </div>
              <div className="flex items-center gap-2">
                <span className="text-white text-lg sm:text-xl font-semibold">
                  OFF
                </span>
                <ToggleSwitch
                  setswitch={handleInputChange}
                  name="google"
                  checked={inputs.google}
                />
                <span className="text-white text-lg sm:text-xl font-semibold">
                  ON
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default Personal
