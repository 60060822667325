import React, { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import ph from '../../assets/icons/phoneblack.svg'
import dollarB from '../../assets/icons/dollarB.svg'
import adres from '../../assets/icons/address.svg'
import card from '../../assets/icons/cardBlack.svg'

const ConfirmationModal = ({ onClose, onConfirm, user, balanceData }) => {
  const [contactInfo, setContactInfo] = useState({
    ...balanceData,
    client_mobile_number: user?.mobile || '',
    client_address: user?.address || '',
  })
  const [error, setError] = useState('')
  const mapref = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mapref.current && !mapref.current.contains(event.target)) {
        onClose()
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [mapref, onClose])

  const handleInputChange = (event) => {
    setError('')
    const { name, value } = event.target
    setContactInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }))
  }

  const handleConfirm = (e) => {
    e.preventDefault()
    if (
      !contactInfo.client_mobile_number ||
      !contactInfo.amount ||
      !contactInfo.client_address ||
      !contactInfo.type
    ) {
      setError('all feailds requaiered')
      return
    }

    onConfirm(contactInfo)
  }

  return (
    <motion.div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div
        ref={mapref}
        className=" bg-blue_primary gap-6 flex justify-center items-center flex-col rounded-lg p-8 w-11/12 md:w-1/2 lg:w-1/2"
      >
        <h2 className="text-xl text-white font-bold">Confirm Your Details</h2>
        <div className=" flex gap-2 flex-col lg:flex-row w-full">
          <div className=" w-full flex flex-col gap-1">
            <label className=" text-white">Phone Number</label>
            <div className=" bg-white text-black_header_3 flex justify-between w-full p-4 rounded-full ">
              <img
                alt=""
                src={ph}
                className="h-6 w-6 text-black_header_3  mr-2"
              />

              <input
                type="text"
                name="client_mobile_number"
                value={contactInfo.client_mobile_number}
                onChange={handleInputChange}
                className=" w-11/12 focus:outline-none"
              />
            </div>
          </div>

          <div className=" w-full flex flex-col gap-1">
            <label className=" text-white"> Address</label>
            <div className=" bg-white text-black_header_3 flex justify-between w-full p-4 rounded-full ">
              <img
                alt=""
                src={adres}
                className="h-6 w-6 text-black_header_3  mr-2"
              />

              <input
                type="text"
                name="client_address"
                value={contactInfo.client_address}
                onChange={handleInputChange}
                className=" w-11/12 focus:outline-none"
              />
            </div>
          </div>
        </div>

        <div className=" flex gap-2 flex-col lg:flex-row w-full">
          <div className=" w-full flex flex-col gap-1">
            <label className=" text-white"> Balance</label>
            <div className=" bg-white text-black_header_3 flex justify-between w-full p-4 rounded-full ">
              <img
                alt=""
                src={dollarB}
                className="h-6 w-6 text-black_header_3  mr-2"
              />

              <input
                type="number"
                name="amount"
                value={contactInfo.amount}
                onChange={handleInputChange}
                className=" w-11/12 focus:outline-none"
              />
            </div>
          </div>

          <div className=" w-full flex flex-col gap-1">
            <label className=" text-white"> Payment Method</label>
            <div className=" bg-white text-black_header_3 flex justify-between w-full p-4 rounded-full ">
              <img
                alt=""
                src={card}
                className="h-6 w-6 text-black_header_3  mr-2"
              />

              <select
                name="type"
                value={contactInfo.type}
                onChange={handleInputChange}
                className="w-11/12 focus:outline-none bg-white"
              >
                <option value="" disabled>
                  Payment Method
                </option>
                <option value="online">Online Payment</option>
                <option value="cash on delivery">Cash On Delivery</option>
                <option value="dept">Dept Payment</option>
              </select>
            </div>
          </div>
        </div>
        {error && (
          <div className=" px-6 flex justify-center items-center py-1 bg-red/65 rounded">
            <div className=" text-white">{error}</div>
          </div>
        )}

        <div className="flex justify-end gap-4">
          <button
            onClick={onClose}
            className="px-8 py-2 bg-gray text-white rounded-full hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={handleConfirm}
            className="px-8 py-2 bg-yellow  text-white rounded-full hover:bg-blue-600"
          >
            Confirm
          </button>
        </div>
      </div>
    </motion.div>
  )
}

export default ConfirmationModal
