import axios from 'axios'

const changePassword = async (passwords) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}api/changePassword`,
      {
        currentPassword: passwords.currentPassword,
        newPassword: passwords.newPassword,
        newPassword_confirmation: passwords.confermPassword,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export default changePassword
