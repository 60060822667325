import { AnimatePresence } from 'framer-motion'
import React, { useState } from 'react'
import CompliantPopup from './CompliantPopup'

const ComplaintRow = ({ complaint, index }) => {
  const [view, setView] = useState(false)
  console.log('com', complaint)
  const formatDate = (dateString) => {
    const options = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    }
    const date = new Date(dateString)
    return date.toLocaleDateString('en-GB', options)
  }

  return (
    <>
      <div
        className={`p-5 pr-0 rounded-lg  md:block shadow-lg ${
          index % 2 === 0
            ? 'bg-blue_primary'
            : ' md:bg-transparent bg-blue_primary '
        }`}
      >
        <div className="grid grid-cols-1 grid-rows-3 md:grid-rows-1 md:grid-cols-7 gap-4">
          <div className=" hidden md:flex col-span-1">
            <span className="block text-white">{complaint?.id}</span>
          </div>
          <div className="flex justify-center items-center md:justify-start col-span-1 md:col-span-2">
            <span className="block text-white">{complaint.text}</span>
          </div>
          <div className="col-span-1 hidden md:flex justify-center items-center">
            <span className=" flex justify-center items-center text-white">
              {complaint.order_id}
            </span>
          </div>
          <div className="col-span-1">
            <span className=" flex justify-center items-center text-white">
              {formatDate(complaint.created_at)}
            </span>
          </div>
          <div className=" col-span-1  text-white flex items-center justify-center">
            <div className="py-1 px-4  text-white">
              {complaint.Response_text ? 'Yes' : 'not yet'}
            </div>
          </div>
          <div className=" col-span-1  text-white flex items-center justify-center">
            <div
              onClick={() => setView(true)}
              className="bg-yellow py-1 px-4 rounded-full text-white cursor-pointer"
            >
              View
            </div>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {view && (
          <CompliantPopup
            complaint={complaint}
            active={view}
            close={() => setView(false)}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default ComplaintRow
