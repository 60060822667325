import { useState } from 'react'
import closedEye from '../../assets/icons/closedEye.svg'
import openedEye from '../../assets/icons/openedEye.svg'
import lock from '../../assets/icons/lock.svg'

const PasswordInput = ({ name, placeholder, value, Change, className }) => {
  const [visibal, setVisibal] = useState(false)
  return (
    <div className={className}>
      <div className="w-1/12">
        <img src={lock} alt="password" />
      </div>
      <input
        name={name}
        value={value}
        onChange={Change}
        type={visibal ? 'text' : 'password'}
        className="text-dark_text w-10/12 focus:outline-none"
        placeholder={placeholder}
        required
      />
      <div
        className="w-1/12 cursor-pointer"
        onClick={() => setVisibal(!visibal)}
      >
        <img src={visibal ? openedEye : closedEye} alt="show password" />
      </div>
    </div>
  )
}

export default PasswordInput
