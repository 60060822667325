import React, { useEffect, useState } from 'react'
import getAllComplaints from '../../apis/getAllComplaints'
import Loading from '../../components/shared/Loading'
import ComplaintRow from '../../components/shared/ComplaintRow'

const Complaints = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchData = async () => {
    try {
      const complaints = await getAllComplaints()
      setData(complaints)
    } catch (error) {
      setData([])
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <div className="w-full p-3 flex flex-col relative">
      <h1 className="text-2xl hidden md:flex font-normal mb-8 text-white">
        Complaints
      </h1>
      <div className="flex flex-col gap-3">
        <div className="p-5 rounded-lg hidden md:block shadow-lg bg-cart">
          <div className="grid grid-cols-7 gap-4">
            <div className="col-span-1">
              <span className="block text-white">Complaint ID</span>
            </div>
            <div className="col-span-2">
              <span className="block text-white">Message</span>
            </div>
            <div className="col-span-1">
              <span className=" flex justify-center items-center text-white">
                Order ID
              </span>
            </div>
            <div className="col-span-1">
              <span className=" flex justify-center items-center text-white">
                Date
              </span>
            </div>
            <div className="col-span-1 text-white flex items-center justify-center">
              Respned
            </div>
            <div className="col-span-1 text-white flex items-center justify-center">
              Action
            </div>
          </div>
        </div>
        <div className="flex pb-5 flex-col gap-3 h-[55vh] px-3 overflow-auto">
          {data.length > 0 ? (
            data.map((complaint, index) => (
              <ComplaintRow complaint={complaint} index={index} key={index} />
            ))
          ) : (
            <div className="flex w-full justify-center items-center text-white">
              No Complaints Yet
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Complaints
