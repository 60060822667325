import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import cart from '../../assets/icons/cart.svg'
import profile from '../../assets/icons/profile.svg'
import Options from '../../assets/icons/options.svg'
import notificationIcon from '../../assets/icons/notification.svg'
import home from '../../assets/icons/home.svg'
import { useCart } from '../../hooks/CartContext'
import { AnimatePresence } from 'framer-motion'
import OptionsPopup from '../header/OptionsPopup'
import NotificationPopup from '../header/NotificationPopup'

const MopileNavigation = () => {
  const { openCart, cartQuantity } = useCart()
  const [active, setActive] = useState(false)
  const [notificationActive, setNotificationActive] = useState(false)
  const sideRef = useRef(null)
  const location = useLocation()
  const isMobile = window.innerWidth <= 768

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sideRef.current && !sideRef.current.contains(event.target)) {
        setActive(false)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [sideRef])

  if (location.pathname === '/' || !isMobile) {
    return <></>
  }

  return (
    <>
      <div
        style={{ boxShadow: `0px 0 30px -1px #222121c9` }}
        className=" flex justify-between   items-center gap-8 py-2 px-4  md:hidden bg-blue_primary rounded-t-2xl fixed bottom-0 w-full left-0  z-20"
      >
        <Link to={'/'} className="   rounded-full p-2">
          <img src={home} alt="" className=" w-14" />
        </Link>

        <Link onClick={openCart} className=" relative  rounded-full p-2">
          {cartQuantity !== 0 && (
            <div
              style={{ lineHeight: '0px' }}
              className="flex items-center justify-center rounded-full top-2 right-0 absolute p-1 aspect-square bg-red text-white text-xs"
            >
              {cartQuantity}
            </div>
          )}
          <img src={cart} alt="" className=" w-14" />
        </Link>

        <Link onClick={() => setActive(!active)} className="  rounded-full p-2">
          <img src={Options} alt="" className=" w-14" />
        </Link>

        <Link
          onClick={() => setNotificationActive(!notificationActive)}
          className="  rounded-full p-2"
        >
          <img src={notificationIcon} alt="" className=" w-14" />
        </Link>

        <Link to={'/profile'} className=" rounded-full p-2">
          <img src={profile} alt="" className=" w-14" />
        </Link>
      </div>
      <div className="absolute top-0">
        <div className=" flex flex-col relative w-screen">
          <AnimatePresence>
            {active && (
              <OptionsPopup active={active} close={() => setActive(false)} />
            )}
            {notificationActive && (
              <NotificationPopup
                active={notificationActive}
                close={() => setNotificationActive(false)}
              />
            )}
          </AnimatePresence>
        </div>
      </div>
    </>
  )
}

export default MopileNavigation
