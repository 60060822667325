import { motion } from 'framer-motion'
import { useCallback, useEffect, useRef, useState } from 'react'
import viewAllCategories from '../../apis/viewAllCategories'
import { Link } from 'react-router-dom'

const Menu = ({ active, close }) => {
  const popupRef = useRef(null)
  const [categories, setCategories] = useState([])

  const fetchCategories = useCallback(async () => {
    try {
      const fetchedCategories = await viewAllCategories()
      setCategories(fetchedCategories)
    } catch (error) {}
  }, [])

  useEffect(() => {
    fetchCategories()
  }, [fetchCategories])

  useEffect(() => {
    const handleBodyOverflow = () => {
      document.body.style.overflow = active ? 'hidden' : 'auto'
    }

    handleBodyOverflow()

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [active])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        close()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [close])
  return (
    <motion.div
      className="fixed inset-0 bg-overlay/30 w-screen z-50 h-screen grid "
      initial={{ opacity: 0, x: -300 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -300 }}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        ref={popupRef}
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -50 }}
        transition={{ duration: 0.3 }}
        className=" absolute h-[93%] md:h-full gap-3 p-4  overflow-hidden lg:w-1/4 w-[60%] z-40 bg-blue_primary rounded-none flex flex-col justify-start items-strat "
      >
        <div className=" text-sm text-white">Categories </div>
        <div className=" flex flex-col  items-start overflow-auto py-2  gap-8 border-t-2 border-white">
          {categories?.map((category, index) => (
            <Link
              to={`category/${category.id}`}
              key={index}
              className=" text-xl w-full text-white"
            >
              {category.title}
            </Link>
          ))}
        </div>
      </motion.div>
    </motion.div>
  )
}

export default Menu
