import { Outlet, useLocation } from 'react-router-dom'
import Sidepar from '../components/layouts/Sidepar'
import sidepareIcon from '../assets/icons/sidepar.svg'
import { useState } from 'react'
import bg from '../assets/backgrounds/background.png'
import './profile.css'

const Portfolio = () => {
  const [active, setActive] = useState(window.innerWidth < 500)
  const routeNames = {
    '/profile': 'Personal Information',
    '/profile/security': 'Security',
    '/profile/account-balance': 'Account Balance',
    '/profile/purchase-history': 'Purchase History',
    '/profile/activity-log': 'Activity Log',
    '/profile/complaints': 'Complaints',
  }
  const location = useLocation()
  const currentRouteName = routeNames[location.pathname]

  return (
    <div
      className={`overflow-x-hidden overflow-y-visible ${
        active ? 'noScroll' : ''
      } flex bg-gradient-to-r`}
    >
      <div
        className={`md:w-2/6 lg:w-1/4 xl:w-[23%] ${
          active ? 'active' : ''
        } sidepar `}
      >
        <Sidepar setActive={setActive} active={active} />
      </div>
      <main
        style={{ backgroundImage: `url(${bg})` }}
        className=" bg-cover bg-center w-full md:w-4/6 lg:w-3/4 xl:w-4/5 overflow-visible  "
      >
        <div className=" md:hidden p-5 bg-blue_primary rounded-b-2xl w-full">
          <div
            onClick={() => {
              setActive(!active)
            }}
            className=" flex md:hidden cursor-pointer"
          >
            <img src={sidepareIcon} alt="" />
            <div className=" text-white text-2xl text-center w-full ">
              {currentRouteName}
            </div>
          </div>
        </div>
        <div className=" p-5">
          <Outlet />
        </div>
      </main>
    </div>
  )
}

export default Portfolio
