import axios from 'axios'

const viewProfileUser = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}api/viewProfileUser`,
      {
        withCredentials: true,
      }
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export default viewProfileUser
