import Hero from '../components/home/Hero'
import ImageCarosel from '../components/home/ImageCarosel'
import getAllCards from '../apis/getAllCards'
import homebg from '../assets/backgrounds/home.png'
import getCurrancy from '../apis/getCurrancy'
import x from '../assets/backgrounds/x.png'
import s from '../assets/backgrounds/square.png'
import Heading from '../components/home/Heading'
import FloatingImage from '../components/home/FloatingImage'
import { useCallback, useEffect, useState } from 'react'
import Loading from '../components/shared/Loading'

const Home = () => {
  const [cards, setCards] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchCardDetails = useCallback(async () => {
    try {
      const cardData = await getAllCards()
      await getCurrancy()

      setCards(cardData)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchCardDetails()
  }, [fetchCardDetails])

  if (loading) {
    return <Loading />
  }

  return (
    <div className=" bg-gradient-to-br  from-gradient_dark to-blue_header_3 overflow-x-hidden min-h-screen ">
      <div>
        <Hero allCards={cards} />

        <div
          className="bg-cover bg-center  flex flex-col  gap-10 py-2 pb-20 relative "
          style={{ backgroundImage: `url(${homebg})` }}
        >
          <FloatingImage
            delay={0}
            img={x}
            top={0}
            left={90}
            floatDirection="left"
          />
          <div className=" z-10">
            <Heading title={'Best Seller'} />
            <ImageCarosel link={`category/1`} images={cards} />
          </div>
          <FloatingImage
            delay={2}
            img={s}
            top={20}
            left={10}
            floatDirection="right"
          />
          <div className="z-10">
            <Heading title={'Comming Soon'} />
            <ImageCarosel link={`category/1`} images={cards} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
