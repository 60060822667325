import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import stripe from '../../assets/images/stripe.png'
import paypal from '../../assets/images/paypal.png'
import blackcardPaymentIcon from '../../assets/icons/cardBlack.svg'
import whitecardPaymentIcon from '../../assets/icons/cardWhite.svg'
import cashBlack from '../../assets/icons/cashBlack.svg'
import cashWhite from '../../assets/icons/cashWhite.svg'
import loacationicon from '../../assets/icons/location.svg'
import { useAuth } from '../../hooks/AuthContext'
import MapPopup from './LocationPicker'
import { useCart } from '../../hooks/CartContext'

const CheckOut = ({
  onClose,
  setCheckOutData,
  checkOutData,
  paymentType,
  setPaymentType,
  handleCardSelect,
  selectedPaymentCard,
  location,
  setLocation,
  loading,
  handelCheckout,
  success,
  error,
}) => {
  const { balance } = useAuth()
  const { currency } = useCart()
  const [isMapOpen, setIsMapOpen] = useState(false)

  const handleOpenMap = () => {
    setIsMapOpen(true)
  }

  const handleCloseMap = () => {
    setIsMapOpen(false)
  }

  const handleLocationSelect = (selectedLocation) => {
    setLocation(selectedLocation)
    handleCloseMap()
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  return (
    <>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.3 }}
          className="z-30 overflow-hidden fixed inset-0 w-screen h-svh flex items-end justify-center"
          onClick={onClose}
        >
          <div
            className="w-[95vw] bg-cart h-[87svh] flex-col justify-center rounded-t-[30px] overflow-hidden md:flex"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="p-4 w-full text-center text-white text-lg">
              Select Payment Type
            </div>
            <form
              className="w-full h-full justify-between flex flex-col p-4 pt-0 pb-14 gap-0"
              onSubmit={handelCheckout}
            >
              <div className="flex w-full gap-3">
                <div
                  className={`p-2 flex-1 aspect-[3/2] grid gap-0 place-items-center rounded-lg cursor-pointer transition-colors duration-300 ${
                    paymentType === 'card'
                      ? 'bg-white text-cart'
                      : 'bg-white/35 text-white'
                  }`}
                  onClick={() => setPaymentType('card')}
                >
                  <img
                    src={
                      paymentType === 'card'
                        ? blackcardPaymentIcon
                        : whitecardPaymentIcon
                    }
                    alt="Card"
                  />
                  <div>Card</div>
                </div>
                <div
                  className={`p-2 flex-1 aspect-[3/2] grid gap-0 place-items-center rounded-lg cursor-pointer transition-colors duration-300 ${
                    paymentType === 'balance'
                      ? 'bg-white text-cart'
                      : 'bg-white/35 text-white'
                  }`}
                  onClick={() => setPaymentType('balance')}
                >
                  <div className="text-2xl font-bold">LOGO</div>
                  <div>My Balance</div>
                </div>
                <div
                  className={`p-2 flex-1 aspect-[3/2] grid gap-0 place-items-center rounded-lg cursor-pointer transition-colors duration-300 ${
                    paymentType === 'delivery'
                      ? 'bg-white text-cart'
                      : 'bg-white/35 text-white'
                  }`}
                  onClick={() => setPaymentType('delivery')}
                >
                  <img
                    src={paymentType === 'delivery' ? cashBlack : cashWhite}
                    alt="Payment on delivery"
                  />
                  <div className="text-center">Payment on delivery</div>
                </div>
              </div>
              {paymentType === 'card' && (
                <div className="flex-col  flex gap-2">
                  <div className="flex  px-10 py-4 items-center justify-around gap-4">
                    <div
                      className={`${
                        selectedPaymentCard === 'stripe'
                          ? 'opacity-100'
                          : 'opacity-30'
                      } w-1/3 cursor-pointer bg-white h-full flex items-center justify-center rounded-lg p-2`}
                      onClick={() => handleCardSelect('stripe')}
                    >
                      <img src={stripe} alt="" />
                    </div>
                    <div
                      className={`${
                        selectedPaymentCard === 'paypal'
                          ? '  opacity-100'
                          : 'opacity-30'
                      } w-1/3 bg-white cursor-pointer h-full flex items-center justify-center rounded-lg p-2`}
                      onClick={() => handleCardSelect('paypal')}
                    >
                      <img src={paypal} alt="" />
                    </div>
                  </div>
                  <div className="flex flex-col w-full gap-4">
                    <div className="w-full flex flex-col">
                      <label
                        htmlFor="cardNumber"
                        className="text-xs text-white"
                      >
                        Credit Card Number
                      </label>
                      <div className="w-full border-[1px] border-white rounded-full py-2 px-5">
                        <input
                          name="cardNumber"
                          id="cardNumber"
                          type="text"
                          placeholder="Credit Card Number"
                          className="focus:outline-none w-full bg-transparent text-white"
                        />
                      </div>
                    </div>
                    <div className="w-full flex flex-col">
                      <label
                        htmlFor="expirationDate"
                        className="text-xs text-white"
                      >
                        Expiration Date
                      </label>
                      <div className="w-full border-[1px] border-white rounded-full py-2 px-5">
                        <input
                          name="expirationDate"
                          id="expirationDate"
                          type="date"
                          placeholder="Expiration Date"
                          className="focus:outline-none w-full bg-transparent text-white"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-full gap-4">
                    <div className="w-full flex flex-col">
                      <label htmlFor="email" className="text-xs text-white">
                        Email Address
                      </label>
                      <div className="w-full border-[1px] border-white rounded-full py-2 px-5">
                        <input
                          name="email"
                          id="email"
                          type="email"
                          placeholder="Email Address"
                          className="focus:outline-none w-full bg-transparent text-white"
                        />
                      </div>
                    </div>
                    <div className="w-full flex flex-col">
                      <label htmlFor="phone" className="text-xs text-white">
                        Phone Number
                      </label>
                      <div className="w-full border-[1px] border-white rounded-full py-2 px-5">
                        <input
                          name="phone"
                          id="phone"
                          type="tel"
                          placeholder="Phone Number"
                          className="focus:outline-none w-full bg-transparent text-white"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {paymentType === 'delivery' && (
                <>
                  <form className=" w-full flex gap-5 flex-col">
                    <div className=" w-full flex-col flex gap-4">
                      <div className=" w-full flex flex-col">
                        <label htmlFor="mobile" className=" text-xs text-white">
                          Phone Number
                        </label>
                        <div className=" w-full border-[1px] border-white rounded-full py-2 px-5">
                          <input
                            name="mobile"
                            id="mobile"
                            type="tel"
                            placeholder="Mobile Number"
                            className=" focus:outline-none w-full text-white bg-transparent"
                            value={checkOutData.client_mobile_number}
                            onChange={(e) =>
                              setCheckOutData({
                                ...checkOutData,
                                client_mobile_number: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div
                        className=" w-full flex flex-col cursor-pointer"
                        onClick={handleOpenMap}
                      >
                        <label
                          htmlFor="address"
                          className=" text-xs text-white flex items-center gap-2 "
                        >
                          <img src={loacationicon} alt="" />
                          <span>Location On The Map</span>
                        </label>
                        <div className=" w-full border-[1px] border-white rounded-full py-2 px-5">
                          <input
                            name="address"
                            id="address"
                            type="text"
                            value={
                              location.lat && location.lng
                                ? `${location.lat}, ${location.lng}`
                                : ''
                            }
                            placeholder="Address"
                            className=" focus:outline-none w-full text-white bg-transparent"
                          />
                        </div>
                      </div>
                    </div>
                    <div className=" w-full flex  flex-col">
                      <label
                        htmlFor="addressWriting"
                        className=" text-xs text-white"
                      >
                        Address In Writing
                      </label>
                      <div className=" w-full border-[1px] border-white rounded-full py-2 px-5">
                        <input
                          name="addressWriting"
                          id="addressWriting"
                          type="text"
                          placeholder="Address In Writing"
                          className=" focus:outline-none w-full text-white bg-transparent"
                          value={checkOutData.client_address}
                          onChange={(e) =>
                            setCheckOutData({
                              ...checkOutData,
                              client_address: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    {checkOutData.client_address &&
                      checkOutData.client_mobile_number && (
                        <div className=" w-full flex  flex-col">
                          <label htmlFor="code" className=" text-xs text-white">
                            Add Code If You Have
                          </label>
                          <div className=" w-full border-[1px] border-white rounded-full py-2 px-5">
                            <input
                              name="code"
                              id="code"
                              type="text"
                              placeholder="Code"
                              className=" focus:outline-none w-full text-white bg-transparent"
                              value={checkOutData.code}
                              onChange={(e) =>
                                setCheckOutData({
                                  ...checkOutData,
                                  code: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      )}
                  </form>
                </>
              )}
              {paymentType === 'balance' && (
                <>
                  <div className="flex w-full justify-center flex-col items-center gap-3">
                    <div className="text-white text-2xl">Your Balance</div>
                    <div className=" flex gap-2 items-end text-2xl text-yellow">
                      {parseFloat(balance * currency.amount).toFixed(2)}
                      <span className=" text-sm">{currency.code}</span>
                    </div>
                  </div>

                  <div className=" w-full flex  flex-col">
                    <label htmlFor="code" className=" text-xs text-white">
                      Add Code If You Have
                    </label>
                    <div className=" w-full border-[1px] border-white rounded-full py-2 px-5">
                      <input
                        name="code"
                        id="code"
                        type="text"
                        placeholder="Code"
                        className=" focus:outline-none w-full text-white bg-transparent"
                        value={checkOutData.code}
                        onChange={(e) =>
                          setCheckOutData({
                            ...checkOutData,
                            code: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </>
              )}

              {success && (
                <div className=" px-6 flex justify-center items-center py-1 bg-green rounded-xl">
                  <div className=" text-white">{success}</div>
                </div>
              )}
              {error && (
                <div className=" px-6 flex justify-center items-center -mb-6 mt-1 py-1 bg-red/65 rounded-xl">
                  <div className=" text-white">{error}</div>
                </div>
              )}
              <div className="w-full flex justify-center items-center p-7">
                <button
                  type="submit"
                  className="flex justify-center items-center w-3/4 bg-yellow py-3 px-10 rounded-full text-xl text-white"
                  disabled={loading}
                >
                  {loading && (
                    <div
                      style={{
                        borderColor: `yellow transparent yellow transparent`,
                      }}
                      className=" border-2 w-1/12 aspect-square animate-spin rounded-full "
                    ></div>
                  )}
                  <span className={`${loading ? 'w-11/12' : 'w-full'}`}>
                    Check Out
                  </span>
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </AnimatePresence>

      <AnimatePresence>
        {isMapOpen && (
          <MapPopup close={handleCloseMap} setLocation={handleLocationSelect} />
        )}
      </AnimatePresence>
    </>
  )
}

export default CheckOut
