import React, { useEffect, useState } from 'react'
import dollarB from '../../assets/icons/dollarB.svg'
import money from '../../assets/icons/money.svg'
import { useAuth } from '../../hooks/AuthContext'
// import { useCart } from '../../hooks/CartContext'
import buyBalance from '../../apis/buyBalance'
import ConfirmationModal from '../../components/profile/ConfirmationModal'
import { AnimatePresence } from 'framer-motion'
import buyDeptBalance from '../../apis/buyDeptBalance'

const Balance = () => {
  const { user } = useAuth()
  // const { currency } = useCart()
  const [loading, setLoading] = useState(false)
  const [balanceData, setBalanceData] = useState({
    amount: '',
    type: '',
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [error, setError] = useState('')
  const [succsess, setSuccsess] = useState('')

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setBalanceData((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }))
  }

  const handleBalance = async (contactInfo) => {
    setIsModalOpen(false)
    setLoading(true)
    if (contactInfo.type === 'cash on delivery') {
      try {
        const res = await buyBalance(contactInfo)
        setSuccsess(res.message)
      } catch (error) {
        setError(error.response.data.message)
      } finally {
        setLoading(false)
        setIsModalOpen(false)
      }
    } else if (contactInfo.type === 'dept') {
      try {
        console.log(contactInfo)
        const res = await buyDeptBalance(contactInfo.amount)
        setSuccsess(res.message)
      } catch (error) {
        setError(error.response.data.message)
      } finally {
        setLoading(false)
        setIsModalOpen(false)
      }
    } else {
      setError('this methode is not supported yet')
      setLoading(false)
    }
  }

  useEffect(() => {
    if (succsess || error) {
      const timer = setTimeout(() => {
        setSuccsess('')
        setError('')
      }, 3000)
      return () => clearTimeout(timer)
    }
  }, [succsess, error])

  const handleOpenModal = (e) => {
    e.preventDefault()
    if (balanceData.type === 'cash on delivery') {
      setIsModalOpen(true)
    } else {
      handleBalance(balanceData)
    }
  }

  return (
    <div className=" w-full p-3 min-h-[60vh] flex ">
      <div className=" w-full md:w-2/3 lg:w-2/5 flex flex-col">
        <h1 className="text-2xl hidden md:flex font-normal mb-8 text-white">
          Account Balance
        </h1>
        <form onSubmit={handleOpenModal} className=" flex flex-col gap-8">
          <div className=" bg-white text-black_header_3 flex justify-between w-full p-4 rounded-full ">
            <img
              alt=""
              src={money}
              className="h-6 w-6 text-black_header_3  mr-2"
            />
            <div className=" w-11/12 flex justify-between items-center text-dark_text ">
              {`${parseFloat(user.account_balance)} `}
              <span className="  text-xs">$</span>
            </div>
          </div>

          <div className=" bg-white text-black_header_3 flex justify-between w-full p-4 rounded-full ">
            <img
              alt=""
              src={dollarB}
              className="h-6 w-6 text-black_header_3  mr-2"
            />
            <input
              className=" w-11/12 focus:outline-none"
              type="number"
              name="amount"
              value={balanceData.amount}
              onChange={handleInputChange}
              placeholder="Balance Value"
            />
          </div>

          <div className="bg-white text-black_header_3 flex justify-between w-full p-4 rounded-full">
            <img
              alt=""
              src={dollarB}
              className="h-6 w-6 text-black_header_3 mr-2"
            />
            <select
              name="type"
              value={balanceData.type}
              onChange={handleInputChange}
              className="w-11/12 focus:outline-none bg-white"
            >
              <option value="" disabled>
                Payment Method
              </option>
              <option value="online">Online Payment</option>
              <option value="cash on delivery">Cash On Delivery</option>
              <option value="dept">Dept Payment</option>
            </select>
          </div>
          {error && (
            <div className=" px-6 flex justify-center items-center py-1 bg-red/65 rounded-xl">
              <div className=" text-white">{error}</div>
            </div>
          )}
          <button
            disabled={!balanceData.amount || !balanceData.type || loading}
            type="submit"
            className="flex justify-center items-center disabled:bg-yellow/70 bg-[#FFC20F] hover:bg-yellow  text-white text-lg w-full font-bold py-3 px-8 rounded-full shadow-lg transition duration-300"
          >
            {loading && (
              <div
                style={{ borderColor: `yellow transparent yellow transparent` }}
                className=" border-2 w-1/12 aspect-square animate-spin rounded-full "
              ></div>
            )}
            <span className={`${loading ? 'w-11/12' : 'w-full'}`}>
              Check Out
            </span>
          </button>

          {succsess && (
            <div className=" px-6 flex justify-center items-center py-1 bg-green rounded-xl">
              <div className=" text-white">{succsess}</div>
            </div>
          )}
        </form>
      </div>
      <AnimatePresence>
        {isModalOpen && (
          <ConfirmationModal
            onClose={() => setIsModalOpen(false)}
            onConfirm={handleBalance}
            user={user}
            balanceData={balanceData}
            handleChange={handleInputChange}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default Balance
