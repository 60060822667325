import { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import xbox from '../assets/icons/xbox.svg'
import globe from '../assets/icons/globe.svg'
import ClippedImageWithBorder from '../components/shared/ClippedImageWithBorder'
import CardPackage from '../components/shared/CardPackage'
import getCard from '../apis/getCard'
import Loading from '../components/shared/Loading'
import DescriptionPopup from '../components/shared/DescriptionPopup'

const Cardsingle = () => {
  const [description, setDescription] = useState([])
  const { cardId } = useParams()
  const [card, setCard] = useState([])

  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false)

  const close = () => {
    setOpen(false)
  }

  const fetchCardDetails = useCallback(async () => {
    try {
      const cardData = await getCard(cardId)
      console.log('card', cardData)
      setCard(cardData)
      setDescription(cardData?.description.split(' ') || '')
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [cardId])

  useEffect(() => {
    fetchCardDetails()
  }, [fetchCardDetails])

  if (loading) {
    return <Loading />
  }

  return (
    <div className="  flex flex-col w-screen min-h-screen  bg-gradient-to-br from-blue_header_3 to-blue_primary">
      {/* header */}
      <div className="hidden px-8 md:flex justify-start gap-20 items-center relative bg-black_header_2/50 w-screen h-[18vh] ">
        <div className=" relative w-[13%] ">
          <div className=" absolute -top-[4rem]">
            <ClippedImageWithBorder imageUrl={card?.image} />
          </div>
        </div>
        <div>
          <h1 className="mt-[-4vh] text-[2.1rem] text-yellow font-bold">
            {card?.title}
          </h1>
        </div>
        <div className="flex items-start space-x-2  ">
          <img alt="xbox" src={xbox} />
          <div className="flex flex-col self-center ">
            <span className="text-white text-xs">Categories</span>
            <div className="text-white text-lg flex justify-center items-center gap-3 font-semibold">
              {card.category_name.map((cat, index) => (
                <div key={index}>{cat}</div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex items-start space-x-2  ">
          <img alt="global" src={globe} />
          <div className="flex flex-col self-center ">
            <span className="text-white text-xs">Country</span>
            <span className="text-white text-lg font-semibold">
              {card?.country_name || 'global'}
            </span>
          </div>
        </div>
      </div>

      {/* header mopile */}
      <div className=" w-screen gap-4 flex-col flex md:hidden">
        <div
          style={{ backgroundImage: `url(${card?.image})` }}
          className=" bg-cover bg-top min-h-[40vh] "
        ></div>
        <div className="justify-center items-center p-3 gap-10 w-full flex  ">
          <div className=" justify-center items-center flex flex-col w-1/2 border-r-[1px] border-gray">
            <img alt="xbox" src={xbox} />
            <span className="text-white text-xs">Categories</span>
            <div className="text-white text-lg flex justify-center flex-col items-center gap-3 ">
              {card.category_name.map((cat, index) => (
                <div key={index}>{cat}</div>
              ))}
            </div>
          </div>

          <div className=" justify-center items-center flex flex-col w-1/2">
            <img alt="xbox" src={globe} />
            <span className="text-white text-xs">Country</span>
            <span className="text-white">{card?.country_name || 'global'}</span>
          </div>
        </div>
        <div className=" text-center w-full p-3 text-yellow text-2xl ">
          {card?.title}
        </div>
      </div>
      {/* detalis */}
      <div className=" flex flex-col-reverse sm:flex-row justify-center gap-8 px-5 lg:px-20 md:px-10 md:pl-32 lg:pl-36 xl:pl-40 md:py-10">
        <div className=" w-full md:w-3/5  lg:w-[45%] flex flex-col  ">
          <div className=" md:pl-32 flex md:flex-col gap-3 py-3 md:py-0">
            <div className=" flex flex-col justify-start items-start  gap-3">
              <div className=" flex justify-start items-center w-full gap-3 ">
                <h2 className="text-white text-2xl font-bold ">Description</h2>
                <div className=" flex md:hidden h-[1px] bg-white w-3/4"></div>
              </div>

              <div className=" text-white">
                {description.slice(0, 30).join(' ')}
                <div
                  onClick={() => setOpen(true)}
                  className=" md:hidden w-full text-yellow text-center"
                >
                  read more
                </div>
              </div>
            </div>
          </div>

          {/* popup mopile */}

          <DescriptionPopup
            text={description.slice(0, 30).join(' ')}
            close={close}
            open={open}
          />

          <div className="hidden md:flex text-white">
            {description.slice(30).join(' ')}
          </div>
        </div>
        <div className=" flex flex-col justify-start  p-2 py-4 items-center w-full md:w-2/5 lg:w-[55%] bg-footer  rounded-lg  ">
          <div className=" text-yellow text-2xl">Our Offers</div>
          <div className="grid grid-cols-1 xl:grid-cols-2 w-full gap-3 max-h-[50vh] md:max-h-[60vh] overflow-auto">
            {card?.packages?.map((pkg) => (
              <CardPackage key={pkg.id} pkg={pkg} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Cardsingle
