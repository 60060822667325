import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Login from '../components/loginSingup/Login'
import Singup from '../components/loginSingup/Singup'
import lines from '../assets/backgrounds/background.png'
import { useAuth } from '../hooks/AuthContext'

const LoginPage = () => {
  const [form, setForm] = useState('login')
  const { isAuthenticated } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/profile', { replace: true })
    }
  }, [isAuthenticated, navigate])

  return (
    <div
      style={{ backgroundImage: `url(${lines})` }}
      className=" bg-cover bg-center  w-screen h-screen flex items-start pt-14 lg:pt-0 lg:items-center justify-center bg-gradient-to-br from-gradient_dark to-gradient_light overflow-x-hidden"
    >
      <div className=" w-[90%] max-w-md bg-white rounded-lg  overflow-hidden z-10 ">
        <div className=" relative flex items-center justify-around p-4 h-1/5 bg-blue_primary">
          <div
            className=" text-xl  md:text-2xl cursor-pointer text-white "
            onClick={() => setForm('login')}
          >
            Log In
          </div>
          <div
            className=" text-xl  md:text-2xl text-white cursor-pointer"
            onClick={() => setForm('singup')}
          >
            Sign Up
          </div>
          <div
            className={`${
              form === 'login' ? ' left-[15%]' : ' left-[63%] '
            } transition-all  duration-300 bg-white h-1 rounded-t-lg w-1/5  absolute bottom-0`}
          ></div>
        </div>
        <div className=" ">{form === 'login' ? <Login /> : <Singup />}</div>
      </div>
    </div>
  )
}

export default LoginPage
