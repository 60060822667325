import { useRef, useState, useEffect } from 'react'
import { motion, useMotionValue, animate } from 'framer-motion'
import arrws from '../../assets/icons/arrows.svg'
import { Link } from 'react-router-dom'
import ss from '../../assets/images/sdefaultliderIMage.png'

const ImageCarousel = ({ images, link }) => {
  const carousel = useRef(null)
  const x = useMotionValue(0)
  const [width, setWidth] = useState(0)

  useEffect(() => {
    const updateWidth = () => {
      if (carousel.current) {
        const totalWidth =
          carousel.current.scrollWidth - carousel.current.offsetWidth
        setWidth(-totalWidth)
      }
    }
    updateWidth()
    window.addEventListener('resize', updateWidth)
    return () => window.removeEventListener('resize', updateWidth)
  }, [images.length])

  const slideRight = () => {
    const currentX = x.get()
    animate(x, Math.min(currentX + 180, 0))
  }

  const slideLeft = () => {
    const currentX = x.get()
    animate(x, Math.max(currentX - 180, width))
  }

  return (
    <div
      ref={carousel}
      className="overflow-x-hidden py-2 justify-center px-1 md:px-10 flex flex-col gap-7"
    >
      <motion.div
        drag="x"
        dragConstraints={{ right: 0, left: width }}
        style={{ x }}
        className="w-fit flex gap-8 md:gap-10 p-4 cursor-grab"
      >
        {images.slice(0, 15).map((image, index) => (
          <motion.div
            key={index}
            className="aspect-[10/16] h-[15rem] lg:h-[20rem] xl:h-[20rem]"
          >
            <Link
              to={`card/${image.id}`}
              className="relative block w-full h-full rounded-xl overflow-hidden bg-cover bg-center group"
              style={{ backgroundImage: `url(${image?.image || ss})` }}
            >
              <div className="absolute bottom-0 left-0 w-full flex items-end justify-center h-full bg-gradient-to-t from-black_header_2/55 to-transparent p-2 bg-black bg-opacity-50 text-white text-center opacity-100 md:opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                {image.title}
              </div>
            </Link>
          </motion.div>
        ))}
      </motion.div>

      <div className="flex items-center justify-center gap-2">
        <img
          src={arrws}
          className="hover:scale-110 hover:scale-x-[-1.1] duration-300 scale-x-[-1] cursor-pointer"
          onClick={slideRight}
          alt="Previous"
        />

        <Link
          to={link}
          className="hover:scale-110 duration-300 text-xl text-white bg-white/60 md:bg-white/30 rounded-full p-2 px-10"
        >
          View All
        </Link>

        <img
          src={arrws}
          className="cursor-pointer hover:scale-110 duration-300"
          onClick={slideLeft}
          alt="Next"
        />
      </div>
    </div>
  )
}

export default ImageCarousel
