import React, { useState, useEffect, useCallback } from 'react'
import eye from '../../assets/icons/eye.svg'
import HistoryPopup from '../../components/shared/HistoryPopup'
import getAllPackageOrders from '../../apis/getAllPackageOrders'
import getAllBalanceOrders from '../../apis/getAllBalanceOrders'
import { AnimatePresence } from 'framer-motion'
import { useCart } from '../../hooks/CartContext'
import { useLocation, useNavigate } from 'react-router-dom'

const History = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { currency } = useCart()
  console.log(currency)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedRow, setSelectedRow] = useState(null)

  const query = new URLSearchParams(location.search)
  const type = query.get('type') || 'package'

  const fetchData = useCallback(async () => {
    setLoading(true)
    if (type === 'package') {
      try {
        const result = await getAllPackageOrders()
        console.log(result)
        setData(result)
      } catch (error) {
        console.error('Failed to fetch', error)
      } finally {
        setLoading(false)
      }
    } else {
      try {
        const result = await getAllBalanceOrders()
        console.log('balance', result)
        setData(result)
      } catch (error) {
        console.error('Failed to fetch', error)
      } finally {
        setLoading(false)
      }
    }
  }, [type])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleIconClick = (row) => {
    setSelectedRow(row)
  }

  const closeModal = () => {
    setSelectedRow(null)
  }

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  const handleTabClick = (newType) => {
    navigate(`?type=${newType}`)
  }

  return (
    <div className="w-full  p-3 flex flex-col relative">
      <h1 className="text-2xl hidden md:flex font-normal mb-8 text-white">
        Purchase History
      </h1>
      <div className="flex space-x-4 mb-3 ">
        <button
          className={`py-2 px-4  ${
            type === 'package'
              ? 'bg-white text-blue_header_3 rounded-lg'
              : ' text-white'
          }`}
          onClick={() => handleTabClick('package')}
        >
          Package Orders
        </button>
        <button
          className={`py-2 px-4  ${
            type === 'balance'
              ? 'bg-white text-blue_header_3 rounded-lg'
              : ' text-white'
          }`}
          onClick={() => handleTabClick('balance')}
        >
          Balance Orders
        </button>
      </div>
      <div className="flex flex-col gap-3  ">
        <div className={`p-5 rounded-lg hidden md:block shadow-lg bg-cart`}>
          <div className="flex  flex-clo md:flex-row gap-4">
            <div className="md:col-span-1 flex-1">
              <span className="block text-white">Order Number</span>
            </div>
            <div className="md:col-span-1  flex-1">
              <span className="block text-white">Status</span>
            </div>
            <div className="md:col-span-1  flex-1">
              <span className="block text-white">Amount</span>
            </div>
            <div className="md:col-span-1  flex-1">
              <span className="block text-white">Date</span>
            </div>
            <div className="md:col-span-1 text-white flex items-center  flex-1  justify-center">
              Action
            </div>
          </div>
        </div>
        <div className="flex pb-5 flex-col gap-3 h-[55vh] px-3 overflow-auto">
          {loading ? (
            <div className=" text-white">Loading ..</div>
          ) : data.length > 0 ? (
            type === 'package' ? (
              data.map((row, index) => (
                <div
                  key={row.id}
                  className={`p-5 rounded-lg shadow-lg space-y-4  ${
                    index % 2 !== 0
                      ? 'bg-blue_primary'
                      : ' md:bg-transparent bg-blue_primary '
                  }`}
                >
                  <div className="flex flex-wrap items-center justify-center md:flex-nowrap md:flex-row w-full gap-4">
                    <div className="md:flex-1 flex items-center ">
                      <span className="block text-white">
                        {row.package_order_id}
                      </span>
                    </div>
                    <div className="md:flex-1 flex items-center">
                      <span className="block text-white">{row.status}</span>
                    </div>
                    <div className="md:flex-1 flex items-center">
                      <span className="block text-white">
                        {parseFloat(row.amount * currency?.amount).toFixed(2)}
                        <span className=" text-xs"> {` ${currency.code}`}</span>
                      </span>
                    </div>
                    <div className="md:flex-1 flex items-center">
                      <span className="block text-white">
                        {formatDate(row.created_at)}
                      </span>
                    </div>

                    <div className="md:flex-1 hidden md:flex items-center justify-center">
                      <button
                        onClick={() => handleIconClick(row)}
                        className="text-white flex gap-2 items-center hover:text-gray-200"
                      >
                        <img src={eye} alt="View" />
                        <span className="">View</span>
                      </button>
                    </div>
                  </div>
                  <div
                    onClick={() => handleIconClick(row)}
                    className=" gap-2 text-white flex md:hidden w-full items-center justify-center pt-4"
                  >
                    <img src={eye} alt="View" />
                    View items
                  </div>
                </div>
              ))
            ) : (
              data.map((row, index) => (
                <div
                  key={row.id}
                  className={`p-5 rounded-lg shadow-lg space-y-4  ${
                    index % 2 !== 0
                      ? 'bg-blue_primary'
                      : ' md:bg-transparent bg-blue_primary '
                  }`}
                >
                  <div className="flex flex-wrap items-center justify-center md:flex-nowrap md:flex-row w-full gap-4">
                    <div className="md:flex-1 flex items-center ">
                      <span className="block text-white">
                        {row.balance_order_id}
                      </span>
                    </div>
                    <div className="md:flex-1  flex items-center">
                      <span className="block text-white">{row.status}</span>
                    </div>
                    <div className="md:flex-1 flex items-center">
                      <span className="block text-white">
                        {parseFloat(row.amount * currency?.amount).toFixed(2)}
                        <span className=" text-xs"> {` ${currency.code}`}</span>
                      </span>
                    </div>
                    <div className="md:flex-1 flex items-center">
                      <span className="block text-white">
                        {formatDate(row.created_at)}
                      </span>
                    </div>

                    <div className="md:flex-1 hidden md:flex items-center justify-center">
                      <button
                        onClick={() => handleIconClick(row)}
                        className="text-white flex gap-2 items-center hover:text-gray-200"
                      >
                        <img src={eye} alt="View" />
                        <span className="">View</span>
                      </button>
                    </div>
                  </div>
                  <div
                    onClick={() => handleIconClick(row)}
                    className=" gap-2 text-white flex md:hidden w-full items-center justify-center pt-4"
                  >
                    <img src={eye} alt="View" />
                    View items
                  </div>
                </div>
              ))
            )
          ) : (
            <div className=" flex w-full justify-center items-center text-white">
              No Orders Yet
            </div>
          )}
        </div>
      </div>
      <AnimatePresence>
        {selectedRow && (
          <div className="w-full relative flex  justify-end items-end">
            <HistoryPopup
              type={type}
              history={{ ...selectedRow, currency: currency }}
              close={closeModal}
            />
          </div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default History
