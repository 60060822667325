import { createContext, useContext, useState, useEffect } from 'react'
import Cart from '../components/shared/Cart'

const CartContext = createContext({})

export function useCart() {
  return useContext(CartContext)
}

export function CartProvider({ children }) {
  const [cartItems, setCartItems] = useState(() => {
    const sessionTimestamp = localStorage.getItem('sessionTimestamp')
    const now = Date.now()

    if (
      sessionTimestamp &&
      now - parseInt(sessionTimestamp) < 24 * 60 * 60 * 1000
    ) {
      // If the sessionTimestamp exists and is within the last 24 hours, load cart items
      const localData = localStorage.getItem('cartItems')
      return localData ? JSON.parse(localData) : []
    } else {
      // Otherwise, initialize the cart as empty and reset the session timestamp
      localStorage.setItem('sessionTimestamp', now.toString())
      return []
    }
  })

  const [currency, setCurrency] = useState(() => {
    const savedCurrency = localStorage.getItem('currency')
    return savedCurrency
      ? JSON.parse(savedCurrency)
      : { code: 'USD', amount: 1 }
  })

  const [open, setOpen] = useState(false)

  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems))
  }, [cartItems, currency])

  useEffect(() => {
    localStorage.setItem('currency', JSON.stringify(currency))
  }, [currency])

  const changeCurrency = (currency) => {
    setCurrency(currency)
  }

  const openCart = () => setOpen(true)
  const closeCart = () => setOpen(false)

  const clearCart = () => {
    setCartItems([])
    localStorage.setItem('cartItems', JSON.stringify(cartItems))
  }

  const getItemQuantity = (id) => {
    return cartItems.find((item) => item.id === id)?.quantity || 0
  }

  const addToCart = (id, quantity, imageUrl) => {
    setCartItems((currentItems) => {
      const itemIndex = currentItems.findIndex((item) => item.id === id)
      if (itemIndex !== -1) {
        const updatedItems = [...currentItems]
        updatedItems[itemIndex] = {
          ...updatedItems[itemIndex],
          quantity: updatedItems[itemIndex].quantity + parseFloat(quantity),
        }
        return updatedItems
      } else {
        return [
          ...currentItems,
          { id, quantity: parseFloat(quantity), imageUrl },
        ]
      }
    })
  }

  const increasQuantity = (id) => {
    setCartItems((currentItems) => {
      const itemIndex = currentItems.findIndex((item) => item.id === id)
      if (itemIndex === -1) {
        return [...currentItems, { id, quantity: 1 }]
      } else {
        return currentItems.map((item, index) => {
          if (index === itemIndex) {
            return { ...item, quantity: item.quantity + 1 }
          }
          return item
        })
      }
    })
  }

  const decreseQuantity = (id) => {
    setCartItems((currentItems) => {
      const itemIndex = currentItems.findIndex((item) => item.id === id)
      if (itemIndex === -1) {
        return currentItems
      } else if (currentItems[itemIndex].quantity === 1) {
        return currentItems.filter((item) => item.id !== id)
      } else {
        return currentItems.map((item, index) => {
          if (index === itemIndex) {
            return { ...item, quantity: item.quantity - 1 }
          }
          return item
        })
      }
    })
  }

  const removeFromCart = (id) => {
    setCartItems((currentItems) => {
      const updatedItems = currentItems.filter((item) => item.id !== id)
      localStorage.setItem('cartItems', JSON.stringify(updatedItems))
      return updatedItems
    })
  }

  const itemExists = (id) => {
    return cartItems.some((item) => item.id === id)
  }

  const cartQuantity = cartItems.reduce(
    (quantity, item) => item.quantity + quantity,
    0
  )

  return (
    <CartContext.Provider
      value={{
        addToCart,
        clearCart,
        itemExists,
        getItemQuantity,
        increasQuantity,
        decreseQuantity,
        removeFromCart,
        openCart,
        closeCart,
        changeCurrency,
        cartQuantity,
        cartItems,
        currency,
      }}
    >
      <Cart open={open} />
      {children}
    </CartContext.Provider>
  )
}
