import axios from 'axios'

const getCategoriesForFooter = async () => {
  try {
    const response = await axios.get(
      `https://giftcardsback.expentech.com/api/viewCategoriesForClient?pageSize=5&pageNumber=1`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    return response.data.data
  } catch (error) {
    throw error
  }
}

export default getCategoriesForFooter
